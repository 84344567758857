import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ILogoProps {
	width?: string;
	height?: string;
}
const QConnectLogo: FC<ILogoProps> = ({ width, height }) => {
	return (
		<svg
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="220 240 930 285"
		// style={{
		//   enableBackground: "new 220 240 930 285",
		// }}
		xmlSpace="preserve"
		width={width ? width : height}
	  >
		<style type="text/css">
		  {
			"\n    .st0{fill:#16A589;}\n\t.st1{fill:#EC7063;enable-background:new    ;}\n\t.st2{fill:#F39C11;enable-background:new    ;}\n  "
		  }
		</style>
		<g>
		  <g>
			<path
			  className="st0"
			  d="M627.9,381.1c0.1,10.3-2.6,21-8.4,29.4l10.7,10.6l-12,12l-11-10.8c-6.8,4.4-15.5,6.7-25.7,6.7 c-32.1,0-46.3-23.3-46.4-46.8c-0.1-23.6,14.7-47.2,46.4-47.2S627.6,357.9,627.9,381.1z M552,382.5c0.3,14.8,10.1,30.7,29.7,30.7 c20.4,0,30.2-17.1,29.5-32.6c-0.6-14.6-8.3-30.3-29.5-30.3S551.8,367.3,552,382.5z"
			/>
			<path
			  className="st1"
			  d="M719.9,416c-9.3,9.2-21.3,13.4-34.3,13.4c-33.5,0-47.7-23.1-47.8-46.5c-0.1-23.6,15.2-47.6,47.8-47.6 c12.2,0,23.9,4.6,33.1,13.8l-11.3,11c-5.9-5.8-13.9-8.5-21.8-8.5c-21.8,0-31.2,16.2-31.1,31.3c0.1,15,8.8,30.6,31.1,30.6 c7.9,0,16.8-3.2,22.7-9.2L719.9,416z"
			/>
			<path
			  className="st1"
			  d="M790.1,395.7c0,18.3-12.5,33.1-33.1,33.1s-33-14.8-33-33.1c0-18.2,12.6-33.1,32.9-33.1 C777.1,362.5,790.1,377.5,790.1,395.7z M739.7,395.7c0,9.7,5.8,18.7,17.3,18.7s17.3-9,17.3-18.7c0-9.5-6.7-18.8-17.3-18.8 C745.6,376.8,739.7,386.1,739.7,395.7z"
			/>
			<path
			  className="st1"
			  d="M847.7,427.4v-33.3c0-9.7-5.3-17-15.3-17c-9.7,0-16.2,8.1-16.2,17.8v32.5h-15.6v-63.7h14.1l1,8.6 c6.4-6.3,12.9-9.5,20.9-9.5c15,0,26.9,11.2,26.9,31.2v33.4H847.7z"
			/>
			<path
			  className="st1"
			  d="M923.8,427.4v-33.3c0-9.7-5.3-17-15.3-17c-9.7,0-16.2,8.1-16.2,17.8v32.5h-15.6v-63.7h14.1l1,8.6 c6.4-6.3,12.9-9.5,20.9-9.5c15,0,26.9,11.2,26.9,31.2v33.4H923.8z"
			/>
			<path
			  className="st1"
			  d="M966.5,401.2c1,7.9,7.9,13.5,19,13.5c5.8,0,13.4-2.2,17-5.9l10.1,9.9c-6.7,7-17.7,10.3-27.3,10.3 c-21.9,0-34.9-13.5-34.9-33.9c0-19.3,13.1-33.3,33.8-33.3c21.3,0,34.6,13.2,32.1,39.3H966.5z M1001,388.2 c-1-8.3-7.5-12.4-16.5-12.4c-8.5,0-15.5,4.1-17.8,12.4H1001z"
			/>
			<path
			  className="st1"
			  d="M1082.8,419.3c-7.3,7.2-15.1,10.1-24.5,10.1c-18.4,0-33.8-11.1-33.8-33.6c0-22.6,15.3-33.6,33.8-33.6 c9,0,16,2.6,22.9,9.4l-9.9,10.4c-3.7-3.4-8.4-5-12.8-5c-10.6,0-18.3,7.7-18.3,18.8c0,12.1,8.3,18.6,18,18.6c5,0,10.1-1.4,13.9-5.3 L1082.8,419.3z"
			/>
			<path
			  className="st1"
			  d="M1111.9,345.9v18h17.5v13.5h-17.7V405c0,6.1,3.4,9,8.3,9c2.4,0,5.3-0.8,7.6-1.9l4.4,13.4 c-4.5,1.8-8.3,2.6-13,2.7c-13.8,0.5-22.8-7.3-22.8-23.2v-27.5h-11.9V364h11.9v-16.4L1111.9,345.9z"
			/>
		  </g>
		  <g>
			<path
			  className="st0"
			  d="M459.1,307.8c-2.3-3.1-4.8-6.1-7.4-9l-6.1,5.2c2.4,2.7,4.7,5.5,6.9,8.5c18.5,24.9,26.6,55.7,22.6,86.7 c-3.4,27.2-16,52-35.6,70.8c-4.3,4.2-12.4,10.4-12.4,10.4c-22.4,16.3-50.5,24.2-78.1,21.9c-29.4-2.5-57.6-16.9-77.4-39.3 c-17.2-19.6-27.4-43.9-29.4-69.6h10.7c-0.2-2.6-0.4-5.3-0.4-7.9h-10.7c0-2.4,0-4.9,0.1-7.3l-8.1-0.4c-1.6,33.4,9.6,65.5,31.6,90.4 c21.1,24,51.3,39.3,82.8,42c3.4,0.3,6.9,0.4,10.3,0.4c28.8,0,57.4-10.2,79.6-28.8c24.8-20.6,40.7-49.7,44.8-81.7 C487.4,367.1,478.8,334.3,459.1,307.8z"
			/>
			<path
			  className="st0"
			  d="M345.5,470.7c4.5,0.7,9,1.1,13.7,1.1c15,0,29.4-3.9,42.1-11c0.7-0.4,1.3-0.7,2-1.1c0,0,0.3-0.2,0.9-0.5 l13.6,14.6c5.8-3.9,10.9-8.1,15.2-12.3L419.5,447c0.9-0.9,1.8-1.8,2.7-2.7c1.2-1.3,2.5-2.7,3.6-4.1l-2.1-1.6l-10.5-8.3 c-13.5,16.3-33.3,25.7-54.2,25.7c-20.9,0-40.7-9.4-54.2-25.7l-12.5,10C306,456.7,324.8,467.4,345.5,470.7z"
			/>
			<path
			  className="st1"
			  d="M359.1,491.6c9.4,0,18.8-1.3,27.9-3.8c7.6-2.1,15-5,21.9-8.8l0-0.1l-3.9-6.9c-14.1,7.6-30,11.6-45.9,11.6 c-54.2,0-98.3-44.8-98.3-99.8c0-52.2,39.7-95.1,90-99.4v11.9c2.7-0.3,5.4-0.4,8.1-0.4v-11.9c0.1,0,0.1,0,0.2,0 c54.2,0,98.3,44.8,98.3,99.8c0,24.1-8.7,47.5-24.4,65.7l6.2,5.1c17-19.7,26.4-44.8,26.4-70.8c0-37.2-18.8-70.1-47.2-89.5l8.6-7.4 c2.4,1.7,4.6,3.5,6.8,5.4l5.4-5.9c-31.9-28-81.6-36.8-123.6-22l-0.2,0.1c-40.9,16.9-71.4,54.3-79.5,97.7l7.9,1.4 c7.7-40.8,36.2-76,74.6-91.8c33.6-11.8,72.5-7.5,101.6,10.4l-8.8,7.6c-15.4-8.8-33.2-13.8-52.1-13.8 c-58.6,0-106.4,48.3-106.4,107.7c0,0.5,0,1,0,1.5c0,2.7,0.2,5.3,0.4,7.9C257.9,448.3,303.6,491.6,359.1,491.6z"
			/>
			<path
			  className="st0"
			  d="M446.1,383.9c0-8.5-1.2-17-3.6-25.2l-4.7,1.3l-10.8,3.1c2,6.7,3,13.7,3,20.8c0,11.7-2.8,23.4-8.2,33.7l11,5.5 l3.4,1.7C442.6,412.3,446.1,398.1,446.1,383.9z"
			/>
			<path
			  className="st0"
			  d="M275.8,358.7c-2.4,8.2-3.6,16.6-3.6,25.2c0,14.2,3.5,28.4,10,41l14.4-7.2c-5.4-10.3-8.2-22-8.2-33.7 c0-7,1-14,3-20.8L275.8,358.7z"
			/>
			<path
			  className="st0"
			  d="M330.4,300.9c-20.5,7.3-38,22.2-48.4,42.4l14.4,7.1c12.3-23.9,36.3-38.7,62.7-38.7 c26.4,0,50.5,14.8,62.7,38.7l10.8-5.4l3.6-1.8c-15-29.2-44.6-47.4-77.2-47.4c-0.1,0-0.1,0-0.2,0c-2.7,0-5.4,0.1-8.1,0.4 C343.8,297.1,336.9,298.6,330.4,300.9z"
			/>
		  </g>
		</g>
	  </svg>

	);
};
QConnectLogo.propTypes = {
	width: PropTypes.string,
	height: PropTypes.string,
};

export default QConnectLogo;

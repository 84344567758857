import { FC, useEffect, useState } from 'react';
import Card, {
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from '../../../widgets/Card';
import Chart, { IChartOptions } from '../../../widgets/Chart';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import Theme from '../../../styles/theme';
import ProductivityRestService from '../services/ProductivityRestService';

const UserContributionOnIssuesChart: FC = () => {

  const { data, isFetching } = useAppSelector((store) => store.productivity.userContributionOnIssues)
  const { data: workspace, timeline } = useAppSelector((store) => store.workspace.selected);

  const repositoryIds = workspace.repositoryIds;
  const startDate = timeline.startDate;
  const endDate = timeline.endDate;

  const [chartState, setChartState] = useState<IChartOptions>({
    series: [],
    options: {
      chart: {
        height: 365,
        type: 'pie',
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      stroke: {
        width: 4, 
        colors: [Theme.colorPalette.primary[200]],

      },
      fill: {
        opacity: 0.6,
      },
      labels: [],
    },
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      repositoryIds.length !== 0 &&
      startDate !== '' &&
      endDate !== ''
    ) {
      dispatch(ProductivityRestService.fetchUserContributionOnIssues({
        startDate,
        endDate,
        repositoryIds,
      }));
    }
  }, [dispatch, repositoryIds, startDate, endDate]);

  useEffect(() => {

    if (data === undefined || data.datasets === undefined || data.datasets.length === 0) return;

    setChartState((state) => ({
      series: data?.datasets ?? [],
      options: {
        ...state.options,
        labels: data?.labels ?? [],
      }
    }));
  }, [data]);

  return (
    <div className='col-lg-12'>
      <Card stretch style={{
        "backgroundColor": Theme.colorPalette.primary[100],
      }}>
        <CardHeader style={{
          "backgroundColor": Theme.colorPalette.primary[100],
          "borderRadius": '2rem 2rem 0rem 0rem'
        }}>
          <CardLabel icon='MultilineChart'>
            <CardTitle>
              {"User Average Time on Issues"}
            </CardTitle>
          </CardLabel>
        </CardHeader>
        <CardBody>
          <Chart
            series={chartState.series}
            options={chartState.options}
            isLoading={isFetching} />
        </CardBody>
      </Card>
    </div>
  );
};

export default UserContributionOnIssuesChart;

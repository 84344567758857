import React, { FC, useEffect, useState } from 'react';
import Card, {
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from '../../../widgets/Card';
import Chart, { IChartOptions } from '../../../widgets/Chart';
import Select from '../../../widgets/forms/Select';
import QualityRestService from '../services/QualityRestService';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import Theme from '../../../styles/theme';


const CodeChurnByContributorsChart: FC = () => {

  const { data, isFetching } = useAppSelector((store) => store.quality.userChurnDataRaw)
  const { data: workspace, timeline } = useAppSelector((store) => store.workspace.selected);

  const repositoryIds = workspace.repositoryIds;
  const startDate = timeline.startDate;
  const endDate = timeline.endDate
  const users: string[] = Object.keys(data);

  const [selectedUser, setSelectedUser] = useState<string>("");
  const [selectedUserData, setSelectedUserData] = useState<any>({});

  const dispatch = useAppDispatch();

  const handleOnChange = (event: any) => {
    setSelectedUser(event.target.value);
  }

  const [chartState, setChartState] = useState<IChartOptions>({
    series: [
      {
        name: "Added LOC",
        data: selectedUserData?.datasets?.[0] ?? [],
      },
      {
        name: "Removed LOC",
        data: selectedUserData?.datasets?.[1] ?? []
      },
    ],
    options: {
      chart: {
        type: 'area',
        height: 365,
        zoom: {
          enabled: true,
        },
      },
      labels: selectedUserData?.labels ?? [],
      xaxis: {
        type: 'datetime',
      },
    }
  });

  useEffect(() => {
    if (
      repositoryIds.length !== 0 &&
      startDate !== '' &&
      endDate !== ''
    ) {
      dispatch(QualityRestService.fetchUserCodeChurnDataRaw({
        startDate,
        endDate,
        repositoryIds,
      }));
    }
  }, [
    dispatch,
    repositoryIds,
    startDate,
    endDate
  ]);

  useEffect(() => {
    if (users && users.length > 0 && selectedUser === "") {
      setSelectedUser(users[0]);
    }
  }, [users, selectedUser]);

  useEffect(() => {

    if (selectedUser && selectedUser.length > 0) {
      const { addedLoc, removedLoc, dates } = data[selectedUser];

      setSelectedUserData({
        datasets: [addedLoc, removedLoc],
        labels: dates,
      })
    }
  }, [data, setSelectedUserData, selectedUser]);

  useEffect(() => {
    setChartState((state) => ({
      series: [
        {
          ...(state?.series?.[0] as {} ?? {}),
          data: selectedUserData?.datasets?.[0] ?? [],
        },
        {
          ...(state?.series?.[1] as {} ?? {}),
          data: selectedUserData?.datasets?.[1] ?? []
        },
      ],
      options: {
        ...state.options,
        xaxis: {
          ...state.options.xaxis,
          categories: selectedUserData?.labels ?? [],
        },
      }
    }));
  }, [selectedUserData]);


  return (
    <div className="col-lg-12">
      <Card stretch style={{
        "backgroundColor": Theme.colorPalette.primary[100],
      }}>
        <CardHeader
          style={{
            "backgroundColor": Theme.colorPalette.primary[100],
            "borderRadius": '2rem 2rem 0rem 0rem'
          }}>
          <div className="col-md-6">
            <CardLabel icon="AreaChart">
              <CardTitle>
                {"Code Churn by Contributor"}
              </CardTitle>
            </CardLabel>
          </div>
          <div className="col-md-6">
            <Select
              ariaLabel={'Select User'}
              onChange={handleOnChange}
              value={selectedUser}
              list={[
                { label: "Select User", value: "" },
                ...users.map((user) => ({ label: user, value: user }))
              ]} />
          </div>
        </CardHeader>
        <CardBody>
          <Chart
            series={chartState.series}
            options={chartState.options}
            isLoading={isFetching}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default CodeChurnByContributorsChart;

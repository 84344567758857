import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import Button from '../../../widgets/Button';
import Card, { CardBody, CardFooter } from '../../../widgets/Card';
import WorkspaceRestService from '../services/WorkspaceRestService';
import WorkspaceRepositoryAnalysis from './WorkspaceRepositoryAnalysis';
import RepositoryRestService from '../../repository/services/RepositoryRestService';
import { validateResponse } from '../../../utils/ResponseHelpers';
import moment from 'moment';
import { useState, useEffect, useCallback } from 'react';

type CardProps = {
  idx: number;
  repository: any;
  workspace: any;
  isOwner: boolean;
};

const RepositoryCard = ({ idx, repository, workspace, isOwner }: CardProps) => {
  const dispatch = useAppDispatch();
  const [analysisDetails, setAnalysisDetails] = useState({
    start_time: null,
    end_time: null,
    tast_status: [
      {
        name: 'Loading task...',
        status: 'pending',
        start_time: null,
        end_time: null,
      },
      {
        name: 'Loading task...',
        status: 'pending',
        start_time: null,
        end_time: null,
      },
      {
        name: 'Loading task...',
        status: 'pending',
        start_time: null,
        end_time: null,
      },
      {
        name: 'Loading task...',
        status: 'pending',
        start_time: null,
        end_time: null,
      },
      {
        name: 'Loading task...',
        status: 'pending',
        start_time: null,
        end_time: null,
      },
      {
        name: 'Loading task...',
        status: 'pending',
        start_time: null,
        end_time: null,
      },
      {
        name: 'Loading task...',
        status: 'pending',
        start_time: null,
        end_time: null,
      },
      {
        name: 'Loading task...',
        status: 'pending',
        start_time: null,
        end_time: null,
      },
      {
        name: 'Loading task...',
        status: 'pending',
        start_time: null,
        end_time: null,
      },
    ],
  });

  const updateWorkspaceRepositoryAnalysisProgress = useCallback(
    (repositoryId: string) => {
      dispatch(WorkspaceRestService.fetchRepositoryAnalysisProgress(repositoryId)).then(
        (response) => {
          if (!validateResponse(response, 'Failed to fetch analysis progress', true)) return;
          const payload = response.payload;

          const upadatedtaskStatus = [];
          const progressData = payload.data;
          const tasksMetadata = progressData.tasks;

          if (progressData.report === undefined) return;

          const report = progressData.report;
          const tasks = progressData.report.tasks;

          if (
            tasksMetadata === undefined ||
            tasksMetadata.length === 0 ||
            tasks === undefined ||
            tasks.length === 0
          )
            return;

          for (let taskMetadata of tasksMetadata) {
            taskMetadata = taskMetadata[0];

            const taskReport = tasks[taskMetadata.index];

            let status = 'pending';
            if (taskReport !== undefined) {
              status = taskReport.status;
            }

            status =
              status === 'completed'
                ? 'completed'
                : status === 'pending'
                  ? 'pending'
                  : 'processing';

            upadatedtaskStatus.push({
              name: taskMetadata?.label?.[status] || taskMetadata.name,
              status: status,
              start_time: taskReport?.['start_time'],
              end_time: taskReport?.['end_time'],
            });
          }

          setAnalysisDetails({
            start_time: report['start_time'],
            end_time: report['end_time'],
            tast_status: upadatedtaskStatus,
          });
        },
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (repository.id === undefined || repository.id === '') return;

    updateWorkspaceRepositoryAnalysisProgress(repository.id);
    const intervalId = setInterval(() => {
      updateWorkspaceRepositoryAnalysisProgress(repository.id);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [updateWorkspaceRepositoryAnalysisProgress, repository.id]);
  const handleDeleteRepository = (repositoryId: string) => {
    dispatch(
      WorkspaceRestService.deleteWorkspaceRepository({
        workspaceId: workspace.id,
        repositoryId,
      }),
    ).then((response) => {
      const payload = response.payload;

      if (payload === null || payload === undefined) {
        toast.error('Failed to delete repository');
        return;
      }

      if (!payload.status) {
        toast.error('Failed to delete repository');
        return;
      }

      toast.success('Repository deleted successfully');

      dispatch(WorkspaceRestService.fetchWorkspaceRepository(workspace.id));
    });
  };

  const handleAnalysis = (repositoryId: string) => {
    dispatch(RepositoryRestService.startRepositoryAnalysis(repositoryId)).then((response) => {
      if (!validateResponse(response, 'Failed to start analysis')) return;

      toast.success(`Started analysis for repository: ${repositoryId}`);
    });
  };

  const [analysisDetailPanelStatus, setAnalysisDetailPanelStatus] = useState(false);
  const handleViewAnalysisDetails = () => {
    setAnalysisDetailPanelStatus(true);
  };

  return (
    <div className='col-md-4' key={idx}>
      <Card stretch>
        <CardBody className='pt-0 d-flex'>
          <div className='col-7'>
            <div className='pb-3'>
              <div className='fw-bold fs-3'>{repository['name']}</div>
              <div className='text-muted'>by {repository['details']['owner']}</div>
            </div>
            <div>
              <span className='fw-bold'>{'Last analysis started: '}</span>
              <span className='ms-1'>
                {moment(analysisDetails['start_time']).format('MMM D YYYY, h:mm A')}
              </span>
            </div>
            <div>
              <span className='fw-bold'>{'Last analysis completed: '}</span>
              <span className='ms-1'>
                {moment(analysisDetails['end_time']).format('MMM D YYYY, h:mm A')}
              </span>
            </div>
          </div>
          <div className='col-3 d-grid gap-3 ms-auto'>
            <Button
              buttonType='primary-btn'
              icon='RemoveRedEye'
              onClick={() => handleViewAnalysisDetails()}>
              <span>{'View details...'}</span>
            </Button>
            <Button
              buttonType='primary-btn'
              icon='Send'
              onClick={() => handleAnalysis(repository.id)}>
              <span>{'Start analysis'}</span>
            </Button>
            <Button
              buttonType='danger-btn'
              icon='Delete'
              onClick={() => handleDeleteRepository(repository.id)}>
              <span>{'Remove'}</span>
            </Button>
          </div>
        </CardBody>
        <CardFooter className='row gx-0 pt-1'>
          <section className='col-12'>
            <WorkspaceRepositoryAnalysis
              repositoryId={repository.id}
              analysisDetailPanelStatus={analysisDetailPanelStatus}
              setAnalysisDetailPanelStatus={setAnalysisDetailPanelStatus}
            />
          </section>
        </CardFooter>
      </Card>
    </div>
  );
};

export default RepositoryCard;

import { useCallback, useEffect, useState } from "react";
import moment from "moment";

import Alert from "../../../widgets/Alert";
import Popovers from "../../../widgets/Popovers";
import Spinner from "../../../widgets/Spinner";
import Button, { IButtonProps } from "../../../widgets/Button";
import OffCanvas, { 
  OffCanvasBody,
  OffCanvasHeader,
  OffCanvasTitle 
} from "../../../widgets/OffCanvas";
import Icon from "../../../wrappers/Icon";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { validateResponse } from "../../../utils/ResponseHelpers";
import InvitationRestService from "../services/InvitationRestService";
import WorkspaceRestService from "../../workspace/services/WorkspaceRestService";

const styledBtn: IButtonProps = {
  color: 'light',
  hoverShadow: 'default',
  isLight: true,
  size: 'lg',
};
const InvitationPanel = () => {

  const [invitationPanelStatus, setInvitationPanelStatus] = useState(false);
  const [isFetchingInvitations, setIsFetchingInvitations] = useState(false);

  const dispatch = useAppDispatch();

  const [invitations, setInvitations] = useState([]);

  const fetchInvitations = useCallback(() => {
    setIsFetchingInvitations(true);
    dispatch(InvitationRestService.getInvitations({
      type: "WORKSPACE_INVITATION"
    })).then(
      (response) => {
        if (!validateResponse(response, "Failed to fetch user invitations you")) return;

        setInvitations(response.payload.data);
      }
    ).finally(() => {
      setIsFetchingInvitations(false);
    })
  }, [dispatch])

  useEffect(() => {
    if (invitationPanelStatus) {
      fetchInvitations()
    }
  }, [invitationPanelStatus, fetchInvitations]);

  const handleAcceptInvitation = (invitationId: string) => {
    dispatch(InvitationRestService.updateInvitationStatus({
      invitationId,
      status: "ACCEPTED",
      type: "WORKSPACE_INVITATION"
    })).then(() => { 
      fetchInvitations(); 
      dispatch(WorkspaceRestService.fetchWorkspaces());
    })
  }

  const handleDeclineInvitation = (invitationId: string) => {
    dispatch(InvitationRestService.updateInvitationStatus({
      invitationId,
      status: "REJECTED",
      type: "WORKSPACE_INVITATION"
    })).then(() => { fetchInvitations(); })

  }

  return <>
    <Popovers trigger='hover' desc='Invitations'>
      <Button
        {...styledBtn}
        onClick={() => setInvitationPanelStatus(true)}
        className='btn-only-icon'
        data-tour='dark-mode'>
        <Icon
          icon={'Email'}
          color={'info'}
          className='btn-icon'
        />
      </Button>
    </Popovers>

    <OffCanvas
      id='notificationCanvas'
      titleId='offcanvasExampleLabel'
      placement='end'
      isOpen={invitationPanelStatus}
      setOpen={setInvitationPanelStatus}>
      <OffCanvasHeader setOpen={setInvitationPanelStatus}>
        <OffCanvasTitle id='offcanvasExampleLabel'>Invitations</OffCanvasTitle>
      </OffCanvasHeader>
      <OffCanvasBody>

        {isFetchingInvitations ? <Spinner /> :
          invitations.map((invitation) => {
            return <Alert isLight color='info' className='d-flex flex-direction-column'>
              <div>
                <p className="">
                  You have been invited to join workspace: <b>{invitation["workspace_name"]}</b> by user: <b>{invitation["by_user_name"]}</b>
                </p>
              </div>
              <div className="d-flex justify-content-end mt-2 mb-3 w-100">
                <Button
                  color='info'
                  size='sm'
                  icon="Check"
                  onClick={() => handleAcceptInvitation(invitation["_id"])}>
                  Accept
                </Button>
                <Button
                  color='danger'
                  size='sm'
                  className='ms-2'
                  icon="Cancel"
                  onClick={() => handleDeclineInvitation(invitation["_id"])}>
                  Decline
                </Button>
              </div>
              <div className="w-100">
                <small className="text-muted d-flex justify-content-end">
                  Sent on {moment(invitation["audit"]["created_on"]).format("LLL")}
                </small>
              </div>

            </Alert>
          })}
      </OffCanvasBody>
    </OffCanvas>
  </>
}

export default InvitationPanel;

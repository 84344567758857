import { FC, useState } from "react";
import { useEffectOnce } from "react-use";

import Button from "../../../widgets/Button"
import Card, {
  CardBody,
  CardFooter,
  CardHeader,
  CardLabel,
  CardTitle
} from "../../../widgets/Card"
import Page from "../../../layouts/Page/Page";
import PageWrapper from "../../../layouts/PageWrapper/PageWrapper";

import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { validateResponse } from "../../../utils/ResponseHelpers";
import Spinner from "../../../widgets/Spinner";
import SubscriptionRestService from "../../subscription/services/SubscriptionRestService";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";

const Subscrptions: FC = () => {

  const [isFetchingPlans, setIsFetchingPlans] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<any[]>([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffectOnce(() => {
    setIsFetchingPlans(true)
    dispatch(SubscriptionRestService.getPlans())
      .then((response) => {

        if (!validateResponse(response, "Failed to fetch subscription plans")) return;

        const payload = response.payload;
        setIsFetchingPlans(false)
        setSubscriptions(payload.items);
      })
  });

  return (
    <PageWrapper
      isProtected={false}
      className='p-0'>
      <Page container="fluid" className='p-0'>
        <Header />
        <div className="container-fluid landing-page">
          <div className="row pt-5">
            {isFetchingPlans ? (
              <div className="d-flex align-items-center justify-content-center"
                style={{
                  "height": "75vh",
                }}>
                <Spinner size={50} tag={"div"} />
              </div>) :
              subscriptions.map((subscription) => (
                <div className="col-4 d-flex" key={subscription["_id"]}>
                  <Card
                    className='shadow-3d-dark'
                    borderSize={2}
                    borderColor={"primary"}>
                    <CardHeader className="d-flex justify-content-center align-items-center">
                      <CardLabel>
                        <CardTitle tag='h4' className='h5'>
                          {subscription["item"]["name"]}
                        </CardTitle>
                      </CardLabel>
                    </CardHeader>
                    <CardBody>
                      <div>{subscription["item"]["description"]}</div>
                      <div className="mt-4">
                        <ul>
                          {
                            (subscription["item"]["features"] ?? []).map((feature: any) => (
                              <li key={feature["id"]}>{feature.content}</li>
                            ))
                          }
                        </ul>
                      </div>
                    </CardBody>
                    <CardFooter className="d-flex justify-content-center align-items-center">
                      <Button
                        className='px-5 py-2'
                        rounded={"pill"}
                        color={"primary"}
                        size={"lg"}
                        onClick={() => navigate("/login")}>

                        {subscription["item"]["cost"] !== -1 ?
                          "Subscribe for $" + subscription["item"]["cost"] + "/" + subscription["item"]["bill_frequency"]
                          : subscription["item"]["cost_message"]}
                      </Button>
                    </CardFooter>
                  </Card>
                </div>
              ))}
          </div>
        </div>
        <Footer />
      </Page>
    </PageWrapper>
  )
}
export default Subscrptions;

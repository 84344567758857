
import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpClient from '../../../libs/HttpClient';


const subscribe = createAsyncThunk(
  'subscription/subscribe',
  async (subscribe: any, { rejectWithValue }) => {
    try {
      return (await HttpClient.post('/payment/subscribe', subscribe)).data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
});

const verifySignature = createAsyncThunk(
  'subscription/verify_signature',
  async (args:{razaorpayResponse: {
    razorpay_payment_id: any;
    razorpay_subscription_id: any;
    razorpay_signature: any;
  }}, { rejectWithValue }) => {
    try {
      return (await HttpClient.post('/payment/verify-signature', args.razaorpayResponse)).data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
});

const getSubscriptionPlans = createAsyncThunk(
  'subscription/plans',
  async (_, { rejectWithValue }) => {
    try {
      return (await HttpClient.get('/payment/plans')).data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
});


const getPlans = createAsyncThunk(
  'plans/list',
  async (_, { rejectWithValue }) => {
    try {
      return (await HttpClient.get('/plans')).data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
});

const cancelSubscription = createAsyncThunk(
  'subscription/cancel',
  async (_, { rejectWithValue }) => {
    try {
      return (await HttpClient.post('/payment/cancel-subscription')).data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
});

const SubscriptionRestService = {
  subscribe,
  verifySignature,
  getSubscriptionPlans,
  getPlans,
  cancelSubscription
};

export default SubscriptionRestService;

import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpClient from '../../../libs/HttpClient';


const synchronizeWithGithub = createAsyncThunk(
  'integration/github/synchronize',
  async (source: string) => {
    return (
      await HttpClient.post(
        '/service/vcs/endpoint/synchronize/repositories',
        {
          source
        }
      )
    ).data;
  }
);

const deleteIntegration = createAsyncThunk(
  'integration/github/delete',
  async (integrationType: string) => {

    integrationType = integrationType.toUpperCase();
    return (
      await HttpClient.delete(
        '/user/integration',
        {
          params: {
            "integration_type": integrationType
          }
        }
      )
    ).data;
  }
);



const fetchJiraConfigurations = createAsyncThunk(
  'integration/jira/add',
  async () => {
    return (
      await HttpClient.get("/service/issue-tracker/endpoint/jira/configuration")
    ).data;
  }
);

const addJiraConfiguration = createAsyncThunk(
  'integration/jira/add',
  async (config: {
    configurationName: string;
    serverUrl: string;
    restApiVersion: string;
    accessToken: string
  }) => {
    return (
      await HttpClient.post(
        '/service/issue-tracker/endpoint/jira/configuration',
        {
          "configuration_name": config.configurationName,
          "server_url": config.serverUrl,
          "rest_api_version": config.restApiVersion,
          "access_token": config.accessToken
        }
      )
    ).data;
  }
);

const deleteJiraConfiguration = createAsyncThunk(
  'integration/jira/delete',
  async (configuration_id: string) => {
    return (
      await HttpClient.delete(
        '/service/issue-tracker/endpoint/jira/configuration',
        {
          params: {
            "configuration_id": configuration_id
          }
        }
      )
    ).data;
  }
);

const IntegrationRestService = {
  deleteIntegration,
  synchronizeWithGithub,
  fetchJiraConfigurations,
  addJiraConfiguration,
  deleteJiraConfiguration
};

export default IntegrationRestService;

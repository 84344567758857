import { createSlice } from '@reduxjs/toolkit';
import SummaryRestService from '../services/SummaryRestService';

interface DataState<T> {
  isFetching: boolean;
  data?: T;
  value?: number;
}

interface TSummaryState {
  refactoringAndSmellData: DataState<{ labels: any; datasets: number[][] }>;
  codeChurnData: DataState<{ labels: string[]; datasets: number[][] }>;
  commitsData: DataState<{ labels: string[]; datasets: number[][] }>;
  mostActiveContributor: DataState<{ labels: any; datasets: number[][] }>;
  addedRepos: DataState<null>;
  activeContributorSum: DataState<null>;
  commitSum: DataState<null>;
  churnSum: DataState<null>;
  prSum: DataState<null>;
  aggSmellDensity: DataState<null>;
  repositoryLeaderboard: { 
    contribBasedOn: string;
    isFetching: boolean;
    data: { labels: any; datasets: number[][]; }; 
  };
  historicalData: DataState<{
    churnSum: number;
    acSum: number;
    reposAdded: number;
    commitSum: number;
    prSum: number;
    labels: any;
    datasets: number[][];
  }>;
  prevAddedRepos: DataState<null>;
  prevActiveContributorSum: DataState<null>;
  prevCommitSum: DataState<null>;
  prevChurnSum: DataState<null>;
  prevPrSum: DataState<null>;
  issuesOpenClosedData: DataState<{ labels: any; datasets: number[][] }>;
  issuesAvgCloseTimeData: DataState<{ labels: any; datasets: number[][] }>;
  issueAvgCloseTimeByUserData: DataState<{ labels: any; datasets: number[][] }>;
}

const initialState: TSummaryState = {
  refactoringAndSmellData: {
    isFetching: false,
    data: {
      labels: [],
      datasets: []
    }
  },
  codeChurnData: {
    isFetching: false,
    data: {
      labels: [],
      datasets: []
    }
  },
  commitsData: {
    isFetching: false,
    data: {
      labels: [],
      datasets: []
    }
  },
  mostActiveContributor: {
    isFetching: false,
    data: {
      labels: [],
      datasets: []
    }
  },
  addedRepos: {
    isFetching: false,
    value: -1
  },
  activeContributorSum: {
    isFetching: false,
    value: -1
  },
  commitSum: {
    isFetching: false,
    value: -1
  },
  prSum: {
    isFetching: false,
    value: -1
  },
  churnSum: {
    isFetching: false,
    value: -1
  },
  aggSmellDensity: {
    isFetching: false,
    value: -1
  },
  repositoryLeaderboard: {
    isFetching: false,
    contribBasedOn: 'commit',
    data: {
      labels: [],
      datasets: []
    }
  },
  historicalData: {
    isFetching: false,
    data: {
      churnSum: 0,
      acSum: 0,
      reposAdded: 0,
      commitSum: 0,
      labels: [],
      datasets: [],
      prSum: 0,
    }
  },
  prevAddedRepos: {
    isFetching: false,
    value: -1
  },
  prevActiveContributorSum: {
    isFetching: false,
    value: -1
  },
  prevCommitSum: {
    isFetching: false,
    value: -1
  },
  prevChurnSum: {
    isFetching: false,
    value: -1
  },
  prevPrSum: {
    isFetching: false,
    value: -1
  },
  issuesOpenClosedData: {
    isFetching: false,
    data: {
      labels: [],
      datasets: []
    }
  },
  issuesAvgCloseTimeData: {
    isFetching: false,
    data: {
      labels: [],
      datasets: []
    }
  },
  issueAvgCloseTimeByUserData: {
    isFetching: false,
    data: {
      labels: [],
      datasets: []
    }
  }
};

export const SummarySlice = createSlice({
  name: 'summaryData',
  initialState,
  reducers: {
    resetState: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(SummaryRestService.fetchCodeChurnData.fulfilled, (state, action) => {
      const {
        dates,
        added_loc,
        removed_loc
      } = action.payload;

      state.codeChurnData = {
        isFetching: false,
        data: {
          labels: dates,
          datasets: [
            added_loc,
            removed_loc,
          ],
        }
      }
    });
    builder.addCase(SummaryRestService.fetchCodeChurnData.pending, (state) => {
      state.codeChurnData.isFetching = true
    });

    builder.addCase(SummaryRestService.fetchCommitsData.fulfilled, (state, action) => {
      const {
        dates,
        commits,
      } = action.payload;

      state.commitsData = {
        isFetching: false,
        data: {
          labels: dates,
          datasets: [
            commits
          ],
        }
      }
    });
    builder.addCase(SummaryRestService.fetchCommitsData.pending, (state) => {
      state.commitsData.isFetching = true
    });

    builder.addCase(SummaryRestService.fetchRefactoringAndSmellsData.fulfilled, (state, action) => {
      const { dates, refactoring, smells } = action.payload;
      state.refactoringAndSmellData = {
        isFetching: false,
        data: {
          labels: dates,
          datasets: [
            refactoring,
            smells,
          ]
        },
      };
    }
    );
    builder.addCase(SummaryRestService.fetchRefactoringAndSmellsData.pending, (state) => {
      state.refactoringAndSmellData.isFetching = true
    });

    builder.addCase(SummaryRestService.fetchRepositoriesAdded.fulfilled, (state, action) => {
      state.addedRepos.value = action.payload.repositories
      state.addedRepos.isFetching = false
    })
    builder.addCase(SummaryRestService.fetchRepositoriesAdded.pending, (state) => {
      state.addedRepos.isFetching = true
    })

    builder.addCase(SummaryRestService.fetchActiveContributorSum.fulfilled, (state, action) => {
      state.activeContributorSum.value = action.payload.count
      state.activeContributorSum.isFetching = false
    })
    builder.addCase(SummaryRestService.fetchActiveContributorSum.pending, (state) => {
      state.activeContributorSum.isFetching = true
    })

    builder.addCase(SummaryRestService.fetchCommitSum.fulfilled, (state, action) => {
      state.commitSum.value = action.payload.totalCommitCount
      state.commitSum.isFetching = false
    })
    builder.addCase(SummaryRestService.fetchCommitSum.pending, (state) => {
      state.commitSum.isFetching = true
    })

    builder.addCase(SummaryRestService.fetchChurnSum.fulfilled, (state, action) => {
      state.churnSum.value = action.payload.churnSum
      state.churnSum.isFetching = false
    })

    builder.addCase(SummaryRestService.fetchPrSum.fulfilled, (state, action) => {
      state.prSum.value = action.payload.activePrsCount
      state.prSum.isFetching = false
    })

    builder.addCase(SummaryRestService.fetchPrSum.pending, (state, action) => {
      state.prSum.isFetching = true
    })

    builder.addCase(SummaryRestService.fetchChurnSum.pending, (state) => {
      state.churnSum.isFetching = true
    })

    builder.addCase(SummaryRestService.fetchAggSmellDensity.fulfilled, (state, action) => {
      state.aggSmellDensity.value = action.payload.smellDensity
      state.aggSmellDensity.isFetching = false
    })
    builder.addCase(SummaryRestService.fetchAggSmellDensity.pending, (state) => {
      state.aggSmellDensity.isFetching = true
    })

    builder.addCase(SummaryRestService.fetchMostActiveContributor.fulfilled, (state, action) => {
      const users = []
      const values = []
      for(let contributor of action.payload){
        users.push(contributor["username"])
        values.push(contributor["commits"])
      }

      state.mostActiveContributor = {
        isFetching : false,
        data: {
          labels: users,
          datasets: [
            values,
          ]
        } 
      }
    })
    builder.addCase(SummaryRestService.fetchMostActiveContributor.pending, (state) => {
      state.mostActiveContributor.isFetching = true
    })

    builder.addCase(SummaryRestService.fetchHistoricalData.fulfilled, (state, action) => {
      const { dates, data, churnSum, acSum, reposAdded, commitSum, prSum } = action.payload;
      state.historicalData = {
        isFetching: false,
        data: {
          churnSum,
          acSum,
          reposAdded,
          commitSum,
          labels: dates,
          datasets: [data],
          prSum
        }
      };
    });
    builder.addCase(SummaryRestService.fetchHistoricalData.pending, (state) => {
      state.historicalData.isFetching = true;
    });
  

    builder.addCase(SummaryRestService.fetchRepositoryLeaderboard.fulfilled, (state, action) => {
      const users = []
      const values = []
      for(let contributor of action.payload){
        users.push(contributor["title"])
        values.push(contributor["value"])
      }

      state.repositoryLeaderboard = {
        contribBasedOn: 'commit',
        isFetching : false,
        data: {
          labels: users,
          datasets: [
            values,
          ]
        } 
      }
    })
    builder.addCase(SummaryRestService.fetchRepositoryLeaderboard.pending, (state) => {
      state.repositoryLeaderboard.isFetching = true
    })
 

    // Previous data reducers
    builder.addCase(SummaryRestService.fetchPrevRepositoriesAdded.fulfilled, (state, action) => {
      state.prevAddedRepos.value = action.payload.repositories;
      state.prevAddedRepos.isFetching = false;
    });
    builder.addCase(SummaryRestService.fetchPrevRepositoriesAdded.pending, (state) => {
      state.prevAddedRepos.isFetching = true;
    });

    builder.addCase(SummaryRestService.fetchPrevActiveContributorSum.fulfilled, (state, action) => {
      state.prevActiveContributorSum.value = action.payload.count;
      state.prevActiveContributorSum.isFetching = false;
    });
    builder.addCase(SummaryRestService.fetchPrevActiveContributorSum.pending, (state) => {
      state.prevActiveContributorSum.isFetching = true;
    });

    builder.addCase(SummaryRestService.fetchPrevCommitSum.fulfilled, (state, action) => {
      state.prevCommitSum.value = action.payload.totalCommitCount;
      state.prevCommitSum.isFetching = false;
    });
    builder.addCase(SummaryRestService.fetchPrevCommitSum.pending, (state) => {
      state.prevCommitSum.isFetching = true;
    });

    builder.addCase(SummaryRestService.fetchPrevChurnSum.fulfilled, (state, action) => {
      state.prevChurnSum.value = action.payload.churnSum;
      state.prevChurnSum.isFetching = false;
    });
    builder.addCase(SummaryRestService.fetchPrevChurnSum.pending, (state) => {
      state.prevChurnSum.isFetching = true;
    });

    builder.addCase(SummaryRestService.fetchPrevPrSum.fulfilled, (state, action) => {
      state.prevPrSum.value = action.payload.activePrsCount;
      state.prevPrSum.isFetching = false;
    });
    builder.addCase(SummaryRestService.fetchPrevPrSum.pending, (state) => {
      state.prevPrSum.isFetching = true;
    });

    builder.addCase(SummaryRestService.fetchIssuesOpenClosedData.fulfilled, (state, action) => {
      const {
        dates,
        closed_issues,
        open_issues
      } = action.payload;

      state.issuesOpenClosedData = {
        isFetching: false,
        data: {
          labels: dates,
          datasets: [
            closed_issues,
            open_issues
          ],
        }
      }
    });
    builder.addCase(SummaryRestService.fetchIssuesOpenClosedData.pending, (state) => {
      state.issuesOpenClosedData.isFetching = true
    });


    builder.addCase(SummaryRestService.fetchIssuesAvgCloseTimeData.fulfilled, (state, action) => {
      const {
        dates,
        average_closing_times,
      } = action.payload;

      state.issuesAvgCloseTimeData = {
        isFetching: false,
        data: {
          labels: dates,
          datasets: [
            average_closing_times,
          ],
        }
      }
    });
    builder.addCase(SummaryRestService.fetchIssuesAvgCloseTimeData.pending, (state) => {
      state.issuesAvgCloseTimeData.isFetching = true
    });


    builder.addCase(SummaryRestService.fetchIssueAvgCloseTimeByUserData.fulfilled, (state, action) => {
      const {
        users,
        average_closing_times
      } = action.payload;

      state.issueAvgCloseTimeByUserData = {
        isFetching: false,
        data: {
          labels: users,
          datasets: [
            average_closing_times,
          ],
        }
      }
    });
    builder.addCase(SummaryRestService.fetchIssueAvgCloseTimeByUserData.pending, (state) => {
      state.issueAvgCloseTimeByUserData.isFetching = true
    });


  },
});

export const SummaryActions = SummarySlice.actions;

export default SummarySlice;

import { RouteObject, useRoutes } from "react-router-dom";
import HomeHeader from "../components/home/HomeHeader";
import WorkspaceHeader from "../components/workspace/WorkspaceHeader";

const HeaderRoutes = () => {
  const routes: RouteObject[]  = [


    {
      path: "/", 
      children: [
        {
          path: "home",
          element: <HomeHeader />
        }
      ]
    },
    {
      path: "/workspace",
      children: [
        {
          path: ":id",
          element: <WorkspaceHeader />,
          children: [
            {
              path: "summary",
              element: <WorkspaceHeader />
            },
            {
              path: "quality",
              element: <WorkspaceHeader />
            },
            {
              path: "productivity",
              element: <WorkspaceHeader />
            },
            {
              path: "settings",
              element: <WorkspaceHeader />
            }
          ]
        }
      ]
    },
    {
      path: "/integrations", 
      children: [
        {
          path: "jira",
          element: <HomeHeader />
        }
      ]
    },
    {
      path: "/manage-subscription",
      element: <HomeHeader />
    },
  ];

  return useRoutes(routes);
}

export default HeaderRoutes;

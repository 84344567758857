import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../hooks/useAppDispatch';
import Button from '../../widgets/Button';
import Card, { CardBody, CardFooter, CardHeader, CardTitle } from '../../widgets/Card';
import WorkspaceRestService from './services/WorkspaceRestService';
import Workspace from './types/Workspace';
import { toast } from 'react-toastify';
import Icon from '../../wrappers/Icon';
import WorkspaceAnalysisProgress from './WorkspaceAnalysisProgress';
import Badge from '../../widgets/Badge';

type CardProps = {
  idx: number;
  workspace: Workspace;
  isOwner: boolean;
};

const WorkspaceCard = ({ idx, workspace, isOwner }: CardProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleWorkspaceSelect = (workspace: Workspace) => {
    navigate(`/workspace/${workspace.id}`, {
      state: {
        id: workspace.id,
      },
    });
  };

  const handleWorkspaceSettings = (workspace: Workspace) => {
    navigate(`/workspace/${workspace.id}/settings`, {
      state: {
        id: workspace.id,
      },
    });
  };

  const handleDeleteWorkspace = (workspaceId: string) => {
    dispatch(WorkspaceRestService.deleteWorkspace(workspaceId)).then((response) => {
      const payload = response.payload;

      if (payload === null || payload === undefined) {
        toast.error('Failed to delete workspace');
        return;
      }

      if (!payload.status) {
        toast.error('Failed to delete workspace');
        return;
      }
      toast.success("I've deleted workspace for you!");
      dispatch(WorkspaceRestService.fetchWorkspaces());
    });
  };
  return (
    <div className='col-md-4' key={idx} onClick={() => handleWorkspaceSelect(workspace)}>
      <Card stretch style={{ cursor: 'pointer' }}>
        <CardHeader className='bg-transparent w-100'>
          <CardTitle className='d-flex justify-content-space-between w-100'>
            <div>
              <p className='fw-bold fs-3'>{workspace.name}</p>
            </div>

            <div style={{ marginLeft: 'auto' }}>
              <div className='d-flex align-items-center'>
                <Icon className='fw-bold ms-2' icon='CustomGithub' size={'2x'} />

                <Badge
                  isLight
                  color={isOwner ? 'success' : 'warning'}
                  className='px-3 py-2 ms-2 w-75'
                  style={{ cursor: 'pointer' }}>
                  {isOwner ? 'OWNED' : 'SHARED'}
                </Badge>
              </div>
            </div>
          </CardTitle>
        </CardHeader>
        <CardBody className='pt-0 d-flex'>
          <div className='gap-2 col-8'>
            <div className='mb-1'>
              <span className='fw-bold'>{'Owned by: '}</span>
              <span className='ms-1'>{workspace['created_by_name']}</span>
            </div>
            <div className='mb-1'>
              <span className='fw-bold'>{'Created on: '}</span>
              <span style={{ fontWeight: 500 }}>
                {moment(workspace['audit']['created_on']).format('LLL')}
              </span>
            </div>
            <div className='mb-1'>
              <span className='fw-bold'>{'Last analysis: '}</span>
              <span className='ms-1'>{moment(workspace['audit']['created_on']).format('LLL')}</span>
            </div>
            <div className='mb-1'>
              <span className='fw-bold'>{'Repositories analyzed: '}</span>
              <span className='ms-1'>{workspace.repositoryIds.length}</span>
            </div>
          </div>

          <div className='col-3 d-grid gap-3 ms-auto'>
            <Button
              buttonType='primary-btn'
              icon='Add'
              title='Add a repository'
              onClick={(event: any) => {
                event.stopPropagation();
                handleWorkspaceSettings(workspace);
              }}>
              Repository
            </Button>
            <Button
              buttonType='primary-btn'
              icon='Add'
              title='Add a member'
              onClick={(event: any) => {
                event.stopPropagation();
                handleWorkspaceSettings(workspace);
              }}>
              Member
            </Button>
            <Button
              buttonType='danger-btn'
              icon={'Delete'}
              title='Delete workspace'
              onClick={(event: any) => {
                event.stopPropagation();
                handleDeleteWorkspace(workspace.id);
              }}>
              Delete
            </Button>
          </div>
        </CardBody>
        <CardFooter className='py-0'>
          <WorkspaceAnalysisProgress workspaceId={workspace.id} />
        </CardFooter>
      </Card>
    </div>
  );
};

export default WorkspaceCard;

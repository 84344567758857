import twitter from '../../assets/images/svg/twitter.svg'
import email from '../../assets/images/svg/email.svg'

const Footer = () => {
  return (
    <div className='container-fluid bg-dark text-white landing-page-footer'>
      <div className='border'></div>
      <div className="row mt-5">
        <div className="col-md-9 col-sm-12 px-2 mt-sm-4 mt-md-0">
          <h4 className=''>
            {"About QConnect"}
          </h4>
          <p className='mt-2 text-secondary'>
            {"Empower your software development team with data-driven insights,"}
            <br/> {"seamlessly integrating with developers tools and platforms"}
            <br/>{"to boost productivity and streamline workflows."}
          </p>

      
          <div className='col-md-2 pt-4'>
            <a href={process.env.REACT_APP_TWITTER_URL} target="_blank" rel="noopener noreferrer">
              <img src={twitter} alt='X'/>
              </a>
              <a href={process.env.REACT_APP_EMAIL_URL} target="_blank" rel="noopener noreferrer">
            <img className='px-3' src={email} alt='email'/>
            </a>

          </div>
        </div>
        <div className="col-md-3 col-sm-12 px-4 mt-sm-4 mt-md-0">
          <h4 className=''>
            {"Company"}
          </h4>
          <div className='mt-5 text-secondary'>
            <p>{"About"}</p>
            {/* <p>{"Features"}</p> */}
            <p>{"Career"}</p>
            <p>{"Contact us"}</p>
            <p>{"Terms & Conditions"}</p>
            <p>{"Privacy Policy"}</p>
          </div>
        </div>
        
        <div >
          <span>{"Platform version"}</span>
          <span className='ms-2'>{`v${process.env.REACT_APP_PLATFORM_VERSION}`}</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;

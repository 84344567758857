import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpClient from '../../../libs/HttpClient';

// export const registerPublicRepository = createAsyncThunk(
//   'repository/public-data',
//   async ({ owner, repo }: { owner: string, repo: string }, thunkAPI) => {
//     const response = await HttpClient.post(
//       '/service/vcs/endpoint/repository/registry/public',
//       {
//         owner,
//         repo,
//       }
//     );

//     if (response.data.status) {
//       thunkAPI.dispatch(fetchRepositories());
//     }
//     return response.data;
//   }
// );

export const updateRepositoryIssueTrackerMapping = createAsyncThunk(
  'repository/issue-tracker/project',
  async (
    {
      configurationId,
      projectKey,
      repositoryId
    }: {
      configurationId: string;
      projectKey: string;
      repositoryId: string;
    }) => {

    const response = await HttpClient.post(
      '/service/issue-tracker/endpoint/mapping',
      {
        "configuration_id": configurationId,
        "project_key": projectKey,
        "repository_id": repositoryId,
      }
    );

    return response.data;

  }
);

export const fetchRepositories = createAsyncThunk(
  'repository/data',
  async (source: string) => {

    const response = await HttpClient.get(
      "/service/vcs/endpoint/repositories", 
      {
        params: {
          source
        }
      }
    );

    return response.data;
  }
);

export const fetchOwnedRepositories = createAsyncThunk(
  'repository/owned-data',
  async (source: string) => {

    const response = await HttpClient.get(
      "/service/vcs/endpoint/repositories", 
      {
        params: {
          source,
          type: "OWNED"
        }
      }
    );

    return response.data;
  }
);

export const synchronizeRepositories = createAsyncThunk(
  'repository/sync',
  async () => {
    const response = await HttpClient.post(
      '/service/vcs/endpoint/synchronize/repositories'
    );
    return response.data;
  }
);

export const startRepositoryAnalysis = createAsyncThunk(
  'repository/analysis/start',
  async (repositoryId: string) => {
    const response = await HttpClient.post(
      '/analysis/start',
      {},
      {
        params: {
          "repository_id": repositoryId
        }
      }
    );
    return response.data;
  }
);

const RepositoryRestService = {
  // registerPublicRepository,
  updateRepositoryIssueTrackerMapping,
  fetchRepositories,
  synchronizeRepositories,
  fetchOwnedRepositories,
  startRepositoryAnalysis
};

export default RepositoryRestService

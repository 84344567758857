import { FC, useCallback, useEffect, useState } from 'react';
import WorkspaceRestService from '../services/WorkspaceRestService';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { validateResponse } from '../../../utils/ResponseHelpers';
import classNames from 'classnames';
import Tooltips from '../../../widgets/Tooltips';
import moment from 'moment';
import OffCanvas, {
  OffCanvasBody,
  OffCanvasHeader,
  OffCanvasTitle,
} from '../../../widgets/OffCanvas';
import Card, { CardBody, CardHeader } from '../../../widgets/Card';

interface IWorkspaceRepositoryAnalysis {
  repositoryId: string;
  analysisDetailPanelStatus: boolean;
  setAnalysisDetailPanelStatus: any;
}

const WorkspaceRepositoryAnalysis: FC<IWorkspaceRepositoryAnalysis> = ({
  repositoryId,
  analysisDetailPanelStatus,
  setAnalysisDetailPanelStatus,
}) => {
  const dispatch = useAppDispatch();

  const [analysisDetails, setAnalysisDetails] = useState({
    start_time: null,
    end_time: null,
    tast_status: [
      {
        name: 'Loading task...',
        status: 'pending',
        start_time: null,
        end_time: null,
      },
      {
        name: 'Loading task...',
        status: 'pending',
        start_time: null,
        end_time: null,
      },
      {
        name: 'Loading task...',
        status: 'pending',
        start_time: null,
        end_time: null,
      },
      {
        name: 'Loading task...',
        status: 'pending',
        start_time: null,
        end_time: null,
      },
      {
        name: 'Loading task...',
        status: 'pending',
        start_time: null,
        end_time: null,
      },
      {
        name: 'Loading task...',
        status: 'pending',
        start_time: null,
        end_time: null,
      },
      {
        name: 'Loading task...',
        status: 'pending',
        start_time: null,
        end_time: null,
      },
      {
        name: 'Loading task...',
        status: 'pending',
        start_time: null,
        end_time: null,
      },
      {
        name: 'Loading task...',
        status: 'pending',
        start_time: null,
        end_time: null,
      },
      {
        name: 'Loading task...',
        status: 'pending',
        start_time: null,
        end_time: null,
      },
    ],
  });

  const updateWorkspaceRepositoryAnalysisProgress = useCallback(
    (repositoryId: string) => {
      dispatch(WorkspaceRestService.fetchRepositoryAnalysisProgress(repositoryId)).then(
        (response) => {
          if (!validateResponse(response, 'Failed to fetch analysis progress', true)) return;
          const payload = response.payload;

          const upadatedtaskStatus = [];
          const progressData = payload.data;
          const tasksMetadata = progressData.tasks;

          if (progressData.report === undefined) return;

          const report = progressData.report;
          const tasks = progressData.report.tasks;

          if (
            tasksMetadata === undefined ||
            tasksMetadata.length === 0 ||
            tasks === undefined ||
            tasks.length === 0
          )
            return;

          for (let taskMetadata of tasksMetadata) {
            taskMetadata = taskMetadata[0];

            const taskReport = tasks[taskMetadata.index];

            let status = 'pending';
            if (taskReport !== undefined) {
              status = taskReport.status;
            }

            status =
              status === 'completed'
                ? 'completed'
                : status === 'pending'
                  ? 'pending'
                  : 'processing';

            upadatedtaskStatus.push({
              name: taskMetadata?.label?.[status] || taskMetadata.name,
              status: status,
              start_time: taskReport?.['start_time'],
              end_time: taskReport?.['end_time'],
            });
          }

          setAnalysisDetails({
            start_time: report['start_time'],
            end_time: report['end_time'],
            tast_status: upadatedtaskStatus,
          });
        },
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (repositoryId === undefined || repositoryId === '') return;

    updateWorkspaceRepositoryAnalysisProgress(repositoryId);
    const intervalId = setInterval(() => {
      updateWorkspaceRepositoryAnalysisProgress(repositoryId);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [updateWorkspaceRepositoryAnalysisProgress, repositoryId]);

  return (
    <div>
      <div className='mb-1'>
        <span className='fw-bold'> {'Repository analysis status'} </span>
      </div>
      <section
        className='d-flex'
        style={{
          height: '15px',
        }}>
        {analysisDetails['tast_status'].map((task, index) => {
          return (
            <Tooltips title={task.name} key={'repository-progress-status-' + index}>
              <div
                className={classNames({
                  'flex-grow-1 border rounded h-100': true,
                  'me-1': index === 0,
                  'ms-1': index === analysisDetails['tast_status'].length - 1,
                  'mx-1': index > 0 && index < analysisDetails['tast_status'].length - 1,
                  'bg-success': task.status === 'completed',
                  'bg-warning': task.status === 'processing',
                  'bg-secondary': task.status === 'pending',
                })}
                style={{
                  width: '100%',
                }}></div>
            </Tooltips>
          );
        })}
      </section>

      <OffCanvas
        id='notificationCanvas'
        titleId='offcanvasExampleLabel'
        placement='end'
        isOpen={analysisDetailPanelStatus}
        setOpen={setAnalysisDetailPanelStatus}>
        <OffCanvasHeader setOpen={setAnalysisDetailPanelStatus}>
          <OffCanvasTitle id='offcanvasExampleLabel'>
            <div className='fw-bold'>Latest analysis status</div>
          </OffCanvasTitle>
        </OffCanvasHeader>
        <OffCanvasBody>
          <section>
            {analysisDetails['tast_status'].map((task, index) => {
              return (
                <Card
                  stretch
                  className={classNames(
                    // {
                    //   border: true,
                    //   'border-success': task.status === 'completed',
                    //   'border-warning': task.status === 'processing',
                    //   'border-secondary': task.status === 'pending',
                    // },
                    'bg-white',
                  )}>
                  <CardHeader className='py-0'>
                    <div py-0>
                      <span className='fw-bold'>{task.name}</span>
                    </div>
                  </CardHeader>
                  <CardBody className='py-1'>
                    <section>
                      <span className='fw-bold'>{'Status: '}</span>
                      <span>{task.status.charAt(0).toUpperCase() + task.status.slice(1)}</span>
                    </section>
                    {task.start_time != null && (
                      <section>
                        <span className='fw-bold'>{'Start time: '}</span>
                        <span>{moment(task.start_time).format('MMM D YYYY, h:mm A')}</span>
                      </section>
                    )}
                    {task.end_time != null && (
                      <section>
                        <span className='fw-bold'>{'End time: '}</span>
                        <span>{moment(task.end_time).format('MMM D YYYY, h:mm A')}</span>
                      </section>
                    )}
                  </CardBody>
                </Card>
              );
            })}
          </section>
        </OffCanvasBody>
      </OffCanvas>
    </div>
  );
};

export default WorkspaceRepositoryAnalysis;

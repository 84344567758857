import { FC, useEffect, useState } from "react";
import moment from "moment";
import { DateRangePicker } from 'react-date-range';
import Popovers from "./Popovers"
import Button from "./Button";

interface IDatePicker {
  startDate: string;
  endDate: string;
  onChange: any;
}

const DatePicker: FC<IDatePicker> = ({ startDate, endDate, onChange }) => {


  if (startDate === undefined || startDate === null || startDate === "") {
    startDate = moment().startOf('day').toISOString();
  }

  if (endDate === undefined || endDate === null || endDate === "") {
    endDate = moment().startOf('day').toISOString();
  }

  const start = moment(startDate);
  const end = moment(endDate);

  const [state, setState] = useState({
    selection: {
      startDate: start.startOf('day').toDate(),
      endDate: end.startOf('day').toDate(),
      key: 'selection',
    },
  });

  useEffect(() => {
    setState((state) => ({
      ...state,
      selection: {
        startDate: moment(startDate).startOf('day').toDate(),
        endDate: moment(endDate).startOf('day').toDate(),
        key: 'selection',
      },
    }));
  }, [startDate, endDate]);

  const handleDateChange = (item: any) => {
    setState((state) => ({ ...state, ...item }));
    onChange(item.selection.startDate.toISOString(), item.selection.endDate.toISOString());
  }

  const datePicker = (
    <DateRangePicker
      onChange={handleDateChange}
      moveRangeOnFirstSelection={false}
      retainEndDateOnFirstSelection={false}
      months={2}
      ranges={[state.selection]}
      maxDate={new Date()}
      direction='horizontal'
      rangeColors={[
        String(process.env.REACT_APP_PRIMARY_COLOR),
        String(process.env.REACT_APP_SECONDARY_COLOR),
        String(process.env.REACT_APP_SUCCESS_COLOR),
      ]}
    />
  );

  return <>
    <Popovers
      placement='bottom-end'
      className='mw-100 overflow-hidden'
      data-tour='date-range-menu'
      bodyClassName='p-0'
      trigger='click'
      desc={datePicker}>
      <Button color='dark' isLight>
        {`${start.format('MMM Do YY')} - ${end.format('MMM Do YY')}`}
      </Button>
    </Popovers>
  </>
}

export default DatePicker;

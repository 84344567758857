const getFromStorage = (key: string) => {
  const value: string | null = localStorage.getItem(key);
  if (value == null) {
    return null;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

const putInStorage = (key: string, value: string) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const removeFromStorage = (key: string) => {
  localStorage.removeItem(key)
};

const LocalStorage = { 
  getFromStorage,
  putInStorage,
  removeFromStorage 
};

export default LocalStorage;

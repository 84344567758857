import { createSlice } from '@reduxjs/toolkit';
import IntegrationRestService from '../services/IntegrationRestService';

interface TIntegrationState {
  jira: {
    configurations: {
      isFetching: boolean
      data: any[]
    }
  } 
}

const initialState: TIntegrationState = {
  jira: {
    configurations: {
        isFetching: false,
        data: [],
    },
  },
};

const updateJiraConfigurations = (state: any, action: any) => {
    state.jira.configurations.isFetching = false;
    if (action.payload !== undefined && action.payload.items !== undefined){
        state.jira.configurations.data = action.payload.items;
    }
}

export const IntegrationSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    resetState: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(
        IntegrationRestService.fetchJiraConfigurations.fulfilled,
        updateJiraConfigurations
    );
    builder.addCase(IntegrationRestService.fetchJiraConfigurations.pending, state => {
      state.jira.configurations.isFetching = true;
    });
  },
});

export const IntegrationActions = IntegrationSlice.actions;

export default IntegrationSlice;

import PageWrapper from '../../../layouts/PageWrapper/PageWrapper';
import Page from '../../../layouts/Page/Page';
import WorkspaceRepositoryList from './WorkspaceRepositoryList';
import WorkspaceMemberList from './WorkspaceMemberList';

const WorkspaceSettings = () => {
  return (
    <PageWrapper isProtected={false} title={'Workspace Settings'} className={'p-0'}>
      <Page container='fluid' className='px-5'>
        <div className='row integration'>
          <WorkspaceRepositoryList />
        </div>
        <div className='row integration'>
          <WorkspaceMemberList />
        </div>
      </Page>
    </PageWrapper>
  );
};

export default WorkspaceSettings;

import { FC } from 'react';
import YoutubeEmbed from '../widgets/YoutubeEmbed';

import QConnectBanner from '../assets/images/svg/banner.svg';
import Footer from '../components/landing/Footer';
import Header from '../components/landing/Header';
import Features from '../components/landing/feature/Features';
import Page from '../layouts/Page/Page';
import PageWrapper from '../layouts/PageWrapper/PageWrapper';

const Landing: FC = () => {
  return (
    <PageWrapper isProtected={false} title={'QConnect'} className='p-0'>
      <Page container='fluid' className='p-0'>
        <Header />
        <div className='container-fluid landing-page'>
          <div
            className='d-flex justify-content-center align-items-center w-100 vh-100'
            style={{
              backgroundImage: `url(${QConnectBanner})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}>
            <div className='text-center'>
              <h1 className='display-1 fw-bolder'>
                Boost your software development productivity and efficiency
              </h1>
              <h1>Track, visualize, optimize, and achieve</h1>
            </div>
          </div>

          <div className='row align-items-center mt-5 p-5'>
            <YoutubeEmbed embedId='OmMl3m4a9P8' />
          </div>

          <div className='row justify-content-center mt-5 '>
            <Features />
          </div>
        </div>
        <Footer />
      </Page>
    </PageWrapper>
  );
};

export default Landing;

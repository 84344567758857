import GithubIntegration from './github/GithubIntegration';
import JiraIntegration from './jira/JiraIntegration';

const IntegrationsList = () => {
  const integrations = [
    {
      type: 'GITHUB',
      component: <GithubIntegration />,
    },
    {
      type: 'JIRA',
      component: <JiraIntegration />,
    },
  ];

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='h4 fw-bold py-3'>Integrations</div>
        </div>

        {integrations.map((integration: any, idx: number) => (
          <div className='col-md-6 col-sm-12 col-lg-3 integration' key={idx}>
            {integration.component}
          </div>
        ))}
      </div>
    </>
  );
};

export default IntegrationsList;

import { FC } from 'react';
import BlackBulletPointsIcon from '../../../assets/images/png/group-6.png';
import WhiteBulletPointsIcon from '../../../assets/images/png/group-6-white.png';
import Button from '../../../widgets/Button';
import { Link } from 'react-router-dom';

export interface IFeatureCardProps {
  background: any;
  heading: string;
  subHeading: string;
  features: {
    design: any;
    points: string[];
  };
  graphic: any;
}

const FeatureCard: FC<IFeatureCardProps> = ({
  background,
  heading,
  subHeading,
  features,
  graphic,
}) => {
  return (
    <div
      className='row rounded'
      style={{
        backgroundColor: '#FFFFFF',
        backgroundImage: `url(${background['url']})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: background['position'],
      }}>
      <div className='col-12'>
        <div className='row'>
          <div className='col-sm-12 col-md-8 p-5'>
            <h1>{heading}</h1>
            <h3 className='fw-normal'>{subHeading}</h3>
            <div className='mt-5'>
              {features.points.map((featurePoint: string, index) => (
                <div key={index} className='d-flex align-items-center mt-2'>
                  <img
                    width={25}
                    src={
                      features.design['color'] === 'white'
                        ? WhiteBulletPointsIcon
                        : BlackBulletPointsIcon
                    }
                    alt='bullet-pointes'
                  />
                  <p className='fs-4' style={{ margin: '0 0 0 1rem', ...features.design }}>
                    {featurePoint}
                  </p>
                </div>
              ))}
            </div>

            <div className='d-flex align-items-center mt-5'>
              <Link to='/overview'>
                <Button
                  buttonType='read-btn'
                  color='dark'
                  icon='ArrowForward'
                  iconDirection={'end'}>
                  {'Read more'}
                </Button>
              </Link>
            </div>
          </div>
          <div
            className='col-sm-12 col-md-4 p-0'
            style={{
              backgroundImage: `url(${graphic})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom right',
              backgroundSize: '30rem',
            }}></div>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;

import { createSlice, current } from '@reduxjs/toolkit';
import QualityRestService from '../services/QualityRestService';

const initialState: {
  smellTypeDensityData: {
    isFetching: boolean,
    data: any,
  },
  smellCategoryDetailsData: {
    isFetching: boolean,
    data: any,
  },
  refactoringFreq: {
    isFetching: boolean,
    data: any,
  },
  fileChurn: {
    isFetching: boolean,
    data: any,
  },
  userChurnDataRaw: {
    isFetching: boolean,
    data: any
  }
} = {
  smellTypeDensityData: {
    isFetching: false,
    data: {},
  },
  smellCategoryDetailsData: {
    isFetching: false,
    data: {},
  },
  refactoringFreq: {
    isFetching: false,
    data: {},
  },
  fileChurn: {
    isFetching: false,
    data: {},
  },
  userChurnDataRaw: {
    isFetching: false,
    data: {}
  }
};

const updateFileChurn = (state: any, action: any) => {
  state.fileChurn = {
    isFetching: false,
    data: {
      datasets: [action.payload.map((item: any) => ({
        x: item.title,
        y: item.value,
      }))]
    }
  }
};

const updateUserCodeChurnDataRaw = (state: any, action: any) => {
  state.userChurnDataRaw.data = action.payload
  state.userChurnDataRaw.isFetching = false
};

const updateSmellDensityOverTime = (state: any, action: any) => {
  const { dates, smells } = action.payload;

  state.smellTypeDensityData = {
    data: {
      labels: dates,
      datasets: Object.entries(smells).map(([_, v]) => v),
    },
    isFetching: false
  }
};

const updateSmellCategoryDetails = (state: any, action: any) => {
  const { dates, smells, smellsCategory } = action.payload;

  state.smellCategoryDetailsData = {
    data: {
      labels: dates,
      datasets: [
        smells,
        ...Object.entries(smellsCategory).map(([_, v]) => v),
      ],
    },
    isFetching: false
  }
};

const updateRefactoringFreq = (state: any, action: any) => {
  const { dates, refactors, refactorCategory } = action.payload;
  state.refactoringFreq = {
    data: {
      labels: dates,
      datasets: [
        {
          label: 'No. of refactorings',
          data: refactors,
        },
        ...Object.entries(refactorCategory).map(([k, v]) => ({
          label: k,
          data: v,
        }))
      ],

    },
    isFetching: false
  };
};

export const QualitySlice = createSlice({
  name: 'quality',
  initialState,
  reducers: {
    updateUserChurn: (state: any, action: any) => {

      const username = action.payload;
      const userData = current(state.userChurnDataRaw.data)[username]

      state.userChurnData = {
        labels: userData.dates,
        datasets: [userData.addedLoc, userData.removedLoc,],
      };

    }
  },
  extraReducers: builder => {
    builder.addCase(
      QualityRestService.fetchUserCodeChurnDataRaw.fulfilled,
      updateUserCodeChurnDataRaw
    );
    builder.addCase(QualityRestService.fetchUserCodeChurnDataRaw.pending, state => {
      state.userChurnDataRaw.isFetching = true;
    });


    builder.addCase(
      QualityRestService.fetchSmellTypeDensityOverTime.fulfilled,
      updateSmellDensityOverTime
    );
    builder.addCase(QualityRestService.fetchSmellTypeDensityOverTime.pending, state => {
      state.smellTypeDensityData.isFetching = true;
    });


    builder.addCase(
      QualityRestService.fetchSmellCategoryDetails.fulfilled,
      updateSmellCategoryDetails
    );
    builder.addCase(QualityRestService.fetchSmellCategoryDetails.pending, state => {
      state.smellCategoryDetailsData.isFetching = true;
    });


    builder.addCase(
      QualityRestService.fetchRefactoringFrequency.fulfilled,
      updateRefactoringFreq
    );
    builder.addCase(QualityRestService.fetchRefactoringFrequency.pending, state => {
      state.refactoringFreq.isFetching = true;
    });


    builder.addCase(
      QualityRestService.fetchFileChurn.fulfilled,
      updateFileChurn
    );
    builder.addCase(QualityRestService.fetchFileChurn.pending, state => {
      state.fileChurn.isFetching = true;
    });
  },
});

export const QualityActions = QualitySlice.actions

export default QualitySlice;



import { FC, useEffect } from "react";
import { useAppSelector } from "../hooks/useAppSelector";
import { Navigate } from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import LocalStorage from "../libs/LocalStorage";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { UserActions } from "../components/user/slice/UserSlice";
import UserRestService from "../components/user/services/UserRestService";

const AuthGuard: FC = () => {
  const { data } = useAppSelector((store: any) => store.user.auth);
  const token = LocalStorage.getFromStorage("token"); 

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(UserRestService.fetchUserDetails())
  }, [dispatch]);

  let isAuthenticated = true;
  if(!data){
    isAuthenticated = false;
    if (token) {
      dispatch(UserActions.setToken(token));
      isAuthenticated = true
    }
  }


  return isAuthenticated ? <AppLayout /> : <Navigate to="/" />
}

export default AuthGuard;

import { TColor } from '../types/color-type';

export interface IColors {
	[key: string]: {
		name: TColor;
		code: string;
	};
}

const buildTheme = () => {
    return {
        colorPalette: {
            halftoned: (color: string) => color + '80',
            mildtone: (color: string) => color + '10',
            transparent: 'transparent',
            black: '#000',
            white: '#fff',
            secondary: '#11cdef',
            highlight: '#2dce89',
            danger: '#f5365c',
            warning: '#fb6340',
            gray: {
                50: '#f7fafc',
                100: '#FED7D7',
                200: '#e9ecef',
                300: '#dee2e6',
                400: '#f6f9fc',
                500: '#ced4da',
                600: '#adb5bd',
                700: '#8898aa',
                800: '#525f7f',
                900: '#212529',
            },
            navy: '#172b4d',
            blue: '#5e72e4',
            blues: {
                50: '#EBF8FF',
                100: '#BEE3F8',
                200: '#90CDF4',
                300: '#63B3ED',
                400: '#4299E1',
                500: '#3182CE',
                600: '#2B6CB0',
                700: '#2C5282',
                800: '#2A4365',
                900: '#1A365D',
            },
            cyan: '#11cdef',
            green: {
                50: '#F0FFF4',
                100: '#C6F6D5',
                200: '#9AE6B4',
                300: '#68D391',
                400: '#48BB78',
                500: '#38A169',
                600: '#2F855A',
                700: '#276749',
                800: '#22543D',
                900: '#1C4532',
            },
            pink: '#f5365c',
            orange: '#fb6340',
            primary: {
                100: "#bfe8e1a8",
                200: "#1c9776"
            }
        },
        colorEnums: {
            PRIMARY: {
                name: 'primary',
                code: String(process.env.REACT_APP_PRIMARY_COLOR),
            },
            SECONDARY: {
                name: 'secondary',
                code: String(process.env.REACT_APP_SECONDARY_COLOR),
            },
            SUCCESS: {
                name: 'success',
                code: String(process.env.REACT_APP_SUCCESS_COLOR),
            },
            INFO: {
                name: 'info',
                code: String(process.env.REACT_APP_INFO_COLOR),
            },
            WARNING: {
                name: 'warning',
                code: String(process.env.REACT_APP_WARNING_COLOR),
            },
            DANGER: {
                name: 'danger',
                code: String(process.env.REACT_APP_DANGER_COLOR),
            },
            DARK: {
                name: 'dark',
                code: String(process.env.REACT_APP_DARK_COLOR),
            },
            LIGHT: {
                name: 'light',
                code: String(process.env.REACT_APP_LIGHT_COLOR),
            },
        },
        fonts: {
            heading: 'Open Sans',
            body: 'Open Sans',
        },
    }
};

const Theme = buildTheme();
export default Theme;

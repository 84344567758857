import { useEffect, useState } from 'react';
import Badge from '../../../widgets/Badge';
import Button from '../../../widgets/Button';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../widgets/Card';
import Spinner from '../../../widgets/Spinner';
import Icon from '../../../wrappers/Icon';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useNavigate } from 'react-router-dom';

const JiraIntegration = () => {
  const { data: user, isFetching } = useAppSelector((store) => store.user.details);

  const [performedJiraIntegration, setPerformedJiraIntegration] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (user === undefined || user.id === undefined || user.id === '') return;

    if (user['integrations'] !== undefined && user.integrations['JIRA'] !== undefined) {
      setPerformedJiraIntegration(true);
    }
  }, [user, user.id]);

  const handleAddJiraIntegration = () => {
    navigate('/integrations/jira');
  };

  return (
    <Card stretch>
      <CardHeader className='p-2 card-header integration-header'>
        <CardLabel className='w-100 d-flex justify-content-center'>
          <CardTitle tag='h4' className='h5'>
            {'Connect with Jira'}
          </CardTitle>
        </CardLabel>
        {
          <Badge
            isLight
            color={performedJiraIntegration ? 'success' : 'danger'}
            className='px-3 py-2 w-25'
          >
            <Icon icon={performedJiraIntegration ? 'Link' : 'LinkOff'} size='lg' className='me-1' />
          </Badge>
        }
      </CardHeader>
      <CardBody className='row'>
        {isFetching ? (
          <section className='d-flex justify-content-center align-items-center p-4'>
            <Spinner />
          </section>
        ) : (
          <>
            <div className='col-3 d-flex justify-content-center'>
              <Icon className='fw-bold' icon='CustomJira' size={'5x'} />
            </div>
            <div className='col-5'>
              <p>Connect your Jira account with QConnect and manage mapping</p>
            </div>
            <div className='col-4'>
              {performedJiraIntegration && (
                <Button
                  color='primary'
                  icon='Sync'
                  className='mb-3 w-100'
                  onClick={(event: any) => {
                    event.stopPropagation();
                    handleAddJiraIntegration();
                  }}>
                  {'Manage'}
                </Button>
              )}
              <Button
                buttonType={!performedJiraIntegration ? 'primary-btn' : 'danger-btn'}
                icon={!performedJiraIntegration ? 'Link' : 'LinkOff'}
                className='mb-3 w-100 mt-2'
                onClick={(event: any) => {
                  event.stopPropagation();
                  handleAddJiraIntegration();
                }}>
                {!performedJiraIntegration ? 'Connect' : 'Disconnect'}
              </Button>
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default JiraIntegration;

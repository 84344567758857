import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpClient from '../../../libs/HttpClient';

// Integrated
const registerUser = createAsyncThunk(
    'user/register',
    async ({
        firstName,
        lastName,
        email,
        password,
    }: { firstName: string; lastName: string; email: string; password: string; }, { rejectWithValue }) => {

        try {
            return (await HttpClient.post('/user/signup', {
                firstname: firstName,
                lastname: lastName,
                email: email,
                password: password
            })).data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

// Integrated
const authenticateUser = createAsyncThunk(
    'user/authenticate',
    async ({ email, password }: { email: string; password: string; }, { rejectWithValue }) => {

        try {
            return (await HttpClient.post('/user/signin', {
                email: email,
                password: password,
            })).data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

const AuthRestService = {
    authenticateUser,
    registerUser,
}

export default AuthRestService;

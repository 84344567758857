import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpClient from '../../../libs/HttpClient';

interface FetchChartParams {
  startDate: string;
  endDate: string;
  repositoryIds: string[];
}

// Integrated
const fetchBranchContribution = createAsyncThunk(
  'productivity/branchContribution',
  async ({ startDate, endDate, repositoryIds }: FetchChartParams) => {
    return (
      await HttpClient.post('/service/vcs/endpoint/branches/contributions-pr', {
        repositoryIds,
        startDate,
        endDate,
      })
    ).data;
  }
);

// Integrated
const fetchUserContribution = createAsyncThunk(
  'productivity/userContribution',
  async ({ startDate, endDate, repositoryIds }: FetchChartParams) => {
    return (
      await HttpClient.post('/service/vcs/endpoint/git-users/active', {
        startDate,
        endDate,
        repositoryIds,
      })
    ).data;
  }
);

// Integrated
const fetchBranchLeaderboard = createAsyncThunk(
  'productivity/branchLeader',
  async ({ startDate, endDate, repositoryIds }: FetchChartParams) => {
    const data = (
      await HttpClient.post(
        '/service/vcs/endpoint/branches/contributions-commit',
        {
          repositoryIds,
          startDate,
          endDate,
        }
      )
    ).data;

    return data.success !== undefined && data.success === false ? [] : data;
  }
);

// Integrated
const fetchTeamContributionOnIssues = createAsyncThunk(
  'productivity/teamContributionOnIssues',
  async ({ startDate, endDate, repositoryIds }: FetchChartParams) => {

    return (
      await HttpClient.post(
        '/service/issue-tracker/endpoint/team-contribution',
        {
          repositoryIds,
          startDate,
          endDate,
        }
      )
    ).data;
  }
);

// Integrated
const fetchUserContributionOnIssues = createAsyncThunk(
  'productivity/userContributionOnIssues',
  async ({ startDate, endDate, repositoryIds }: FetchChartParams) => {

    return (
      await HttpClient.post(
        '/service/issue-tracker/endpoint/user-contribution',
        {
          repositoryIds,
          startDate,
          endDate,
        }
      )
    ).data;
  }
);

// Integrated
const fetchUserAverageTimeOnIssues = createAsyncThunk(
  'productivity/userAvgTimeOnIssues',
  async ({ startDate, endDate, repositoryIds }: FetchChartParams) => {

    return (
      await HttpClient.post(
        '/service/issue-tracker/endpoint/user-average-time',
        {
          repositoryIds,
          startDate,
          endDate,
        }
      )
    ).data;
  }
);

// Integrated
const fetchPRClosingTime = createAsyncThunk(
  'productivity/averagePRClosingTime',
  async ({ startDate, endDate, repositoryIds }: FetchChartParams) => {

    return (
      await HttpClient.post(
        '/service/vcs/endpoint/pull-requests/average-time-close',
        {
          repositoryIds,
          startDate,
          endDate,
        }
      )
    ).data;
  }
);

// Integrated
const fetchActiveAndNewPRs = createAsyncThunk(
  'productivity/activeAndNewPRs',
  async ({ startDate, endDate, repositoryIds }: FetchChartParams) => {

    return (
      await HttpClient.post('/service/vcs/endpoint/pull-requests/active-new', {
        repositoryIds,
        startDate,
        endDate,
      })
    ).data;
  }
);

const fetchCommitsOnTicket = createAsyncThunk(
  'productivity/commitsOnTicket',
  async ({ startDate, endDate, repositoryIds }: FetchChartParams) => {

    return (
      await HttpClient.post(
        '/service/issue-tracker/endpoint/commits/associated',
        {
          startDate,
          endDate,
          repositoryIds,
        }
      )
    ).data;
  }
);

const ProductivityRestService = {
  fetchBranchContribution,
  fetchUserContribution,
  fetchBranchLeaderboard,
  fetchTeamContributionOnIssues,
  fetchUserContributionOnIssues,
  fetchUserAverageTimeOnIssues,
  fetchPRClosingTime,
  fetchActiveAndNewPRs,
  fetchCommitsOnTicket,
};

export default ProductivityRestService;

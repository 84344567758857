import { useContext } from 'react';
import { Link } from 'react-router-dom';

import QConnectLogo from '../../assets/logos/QConnectLogo';
import { HeaderRight } from '../../layouts/Header/Header';
import Nav, { NavItem } from '../../widgets/Nav';
import Button, { IButtonProps } from '../../widgets/Button';
import Popovers from '../../widgets/Popovers';
import useDarkMode from '../../hooks/useDarkMode';
import ThemeContext from '../../contexts/themeContext';
import ManageProfile from '../header/ManageProfile';
import InvitationPanel from './invitations/InvitationsPanel';

const HomeHeader = () => {
  const { darkModeStatus } = useDarkMode();
  const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
  const styledBtn: IButtonProps = {
    color: darkModeStatus ? 'dark' : 'light',
    hoverShadow: 'default',
    isLight: !darkModeStatus,
    size: 'lg',
  };

  return (
    <div className='container-fluid p-3 bg-secondary sticky-top'>
      <div className='row'>
        <div className='col-sm-12'>
          <Nav
            tag='nav'
            design='pills'
            isFill={true}
            isJustified={true}
            isVertical={false}
            className='row align-items-center'>
            <div className='col-md-3 pe-5 col-sm-12 d-flex justify-content-start'>
              <NavItem className='w-75 me-auto'>
                <Link to='/' className='p-0'>
                  <QConnectLogo width={'12rem'} />
                </Link>
              </NavItem>
            </div>
            <div className='col-md-6 pe-5 col-sm-12 d-flex justify-content-start'>
              <NavItem>
                <Link className='fs-5' to='#'>
                  {'How QConnect can help'}
                </Link>
              </NavItem>
            </div>

            <HeaderRight className='col-md-3'>
              <div className='row g-3 justify-content-end'>
                {/* Invitations */}
                <div className='col-auto'>
                  <InvitationPanel />
                </div>

                {/*	Full Screen */}
                <div className='col-auto'>
                  <Popovers trigger='hover' desc='Fullscreen'>
                    <Button
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...styledBtn}
                      icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
                      onClick={() => setFullScreenStatus(!fullScreenStatus)}
                      aria-label='Toggle dark mode'
                    />
                  </Popovers>
                </div>

                {/*	Profile */}
                <div className='col-auto ms-5'>
                  <ManageProfile />
                </div>
              </div>
            </HeaderRight>
          </Nav>
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;

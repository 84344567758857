import { FC, useEffect } from 'react';

import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import Spinner from '../../../widgets/Spinner';
import WorkspaceRestService from '../services/WorkspaceRestService';
import AddRepository from './AddRepository';
import RepositoryCard from './RepositoryCard';

const WorkspaceRepositoryList: FC = () => {
  const { data: workspace, repositories } = useAppSelector((store) => store.workspace.selected);
  const { data: user } = useAppSelector((store) => store.user.details);
  const dispatch = useAppDispatch();
  const isOwner = workspace.created_by === user.id;

  useEffect(() => {
    if (workspace.id === '') {
      return;
    }

    dispatch(WorkspaceRestService.fetchWorkspaceRepository(workspace.id));
  }, [dispatch, workspace.id]);

  return (
    <div className='row'>
      <div className='col-12'>
        <div className='h4 fw-bold py-3'>Repositories</div>
      </div>

      {repositories.isFetching ? (
        <div
          className='d-flex align-items-center justify-content-center'
          style={{ minHeight: '50vh' }}>
          <Spinner size={50} tag={'div'} />
        </div>
      ) : (
        <>
          {isOwner && (
            <div className='col-md-3'>
              <AddRepository />
            </div>
          )}
          {repositories.data.map((repository: any, idx: number) => (
            <RepositoryCard
              idx={idx}
              repository={repository}
              workspace={workspace}
              isOwner={isOwner}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default WorkspaceRepositoryList;

import LocalStorage from '../../libs/LocalStorage';
import Avatar from '../../widgets/Avatar';
import Button from '../../widgets/Button';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../widgets/Dropdown';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { UserActions } from '../user/slice/UserSlice';
import GenericUser from '../../assets/images/png/person.png';
import { SummaryActions } from '../summary/slice/SummarySlice';
import { WorkspaceActions } from '../workspace/slice/WorkspaceSlice';
import { IntegrationActions } from '../integrations/slice/IntegrationSlice';
import { RepositoryActions } from '../repository/slice/RepositorySlice';

const ManageProfile = () => {
  const dispatch = useAppDispatch();

  const handleSignOut = () => {
    LocalStorage.removeFromStorage('token');
    dispatch(WorkspaceActions.resetState())
    dispatch(SummaryActions.resetState())
    dispatch(IntegrationActions.resetState())
    dispatch(RepositoryActions.resetState())
    dispatch(UserActions.resetState());
  };

  return (
    <div className='col d-flex align-items-center cursor-pointer'>
      <Dropdown>
        <DropdownToggle hasIcon={true}>
          <Avatar src={GenericUser} size={48} color='brand-two' />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>
            <Button icon='Logout' onClick={handleSignOut}>
              {'Sign Out'}
            </Button>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default ManageProfile;

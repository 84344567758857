import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import RepositoryRestService from '../../repository/services/RepositoryRestService';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import Button from '../../../widgets/Button';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../widgets/Card';
import FormGroup from '../../../widgets/forms/FormGroup';
import FormGroupWrapper from '../../../widgets/forms/FormGroupWrapper';
import Select from '../../../widgets/forms/Select';
import WorkspaceRestService from '../services/WorkspaceRestService';
import { validateResponse } from '../../../utils/ResponseHelpers';

const AddRepositorySchema = Yup.object().shape({
  repositoryId: Yup.string().required('Repository name is required'),
});

const AddRepository = () => {
  const { data: workspace } = useAppSelector((store) => store.workspace.selected);
  const [allRepositoriesState, setAllRepositoriesState] = useState([
    {
      text: 'Select Repository',
      value: '',
    },
  ]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (workspace.integration === '') {
      return;
    }

    dispatch(RepositoryRestService.fetchRepositories(workspace.integration)).then((response) => {
      if (!validateResponse(response, 'Failed to fetch repositories')) return;

      const payload = response.payload;

      setAllRepositoriesState(() => [
        {
          text: 'Select Repository',
          value: '',
        },
        ...payload.items.map((repository: any) => ({
          text: repository['name'],
          value: repository['id'],
        })),
      ]);
    });
  }, [dispatch, workspace.integration]);

  const repositoryFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      repositoryId: '',
    },
    validationSchema: AddRepositorySchema,
    validateOnChange: false,
    onSubmit: async (values: { repositoryId: string }) => {
      await handleAddRepository(values.repositoryId);
    },
  });

  const handleAddRepository = async (repositoryId: string) => {
    try {
      const response = await dispatch(
        WorkspaceRestService.addWorkspaceRepository({
          workspaceId: workspace.id,
          repositoryId: repositoryId,
        }),
      );

      if (!validateResponse(response, 'Failed to add repository')) return;

      toast.success('Repository added successfully');
      dispatch(WorkspaceRestService.fetchWorkspaceRepository(workspace.id));
    } catch (error: any) {
      toast.error('Failed to add repository');
      return;
    }
  };

  return (
    <Card stretch>
      <CardHeader className='p-2 card-header integration-header'>
        <CardLabel className='w-100 d-flex justify-content-center'>
          <CardTitle tag='h4' className='h5'>
            {'Add a new repository'}
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className='d-flex align-items-center justify-content-center'>
        <form className='row w-100'>
          <div className='col-8'>
            <FormGroupWrapper isError={!!repositoryFormik.errors.repositoryId}>
              <FormGroup id='repositoryId' isFloating label='Select Repository' className='w-100'>
                <Select
                  id='repositoryId'
                  name='repositoryId'
                  ariaLabel='Select Repository'
                  placeholder={'Select Repository'}
                  value={repositoryFormik.values.repositoryId}
                  isTouched={repositoryFormik.touched.repositoryId}
                  invalidFeedback={repositoryFormik.errors.repositoryId}
                  isValid={repositoryFormik.isValid}
                  onChange={repositoryFormik.handleChange}
                  onBlur={repositoryFormik.handleBlur}
                  onFocus={() => {
                    repositoryFormik.setErrors({});
                  }}
                  list={allRepositoriesState}
                />
              </FormGroup>
            </FormGroupWrapper>
          </div>
          <div className='col-4 m-0'>
            <Button
              color='primary'
              size='lg'
              className='w-100'
              icon='AccountTree'
              onClick={repositoryFormik.handleSubmit}>
              Add
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  );
};

export default AddRepository;

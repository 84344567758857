import React, { useRef, useContext, useLayoutEffect, useEffect } from 'react';
import { useFullscreen } from 'react-use';
import { ThemeProvider } from 'react-jss';
import { ToastContainer } from 'react-toastify';

import { ReactNotifications } from 'react-notifications-component';
import { Provider } from 'react-redux';

import { getOS } from './utils/GenericHelpers';
import useDarkMode from './hooks/useDarkMode';
import ThemeContext from './contexts/themeContext';
import Theme from './styles/theme';
import store from './store';
import Portal from './layouts/Portal/Portal';
import Wrapper from './layouts/Wrapper/Wrapper';
import 'react-toastify/dist/ReactToastify.css';
const App: React.FC = () => {

  getOS();

  /**
   * Dark Mode
   */
  const { themeStatus, darkModeStatus } = useDarkMode();
  const theme = {
    theme: themeStatus,
    primary: Theme.colorEnums.PRIMARY.code,
    secondary: Theme.colorEnums.SECONDARY.code,
    success: Theme.colorEnums.SUCCESS.code,
    info: Theme.colorEnums.INFO.code,
    warning: Theme.colorEnums.WARNING.code,
    danger: Theme.colorEnums.DANGER.code,
    dark: Theme.colorEnums.DARK.code,
    light: Theme.colorEnums.LIGHT.code,
  };

  useEffect(() => {
    if (darkModeStatus) {
      document.documentElement.setAttribute('theme', 'dark');
    }
    return () => {
      document.documentElement.removeAttribute('theme');
    };
  }, [darkModeStatus]);

  /**
   * Full Screen
   */
  const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
  const ref = useRef(null);
  useFullscreen(ref, fullScreenStatus, {
    onClose: () => setFullScreenStatus(false),
  });

  /**
   * Modern Design
   */
  useLayoutEffect(() => {
    if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
      document.body.classList.add('modern-design');
    } else {
      document.body.classList.remove('modern-design');
    }
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <ToastContainer />
          <Provider store={store}>
            <div
              ref={ref}
              className='app'
              style={{
                backgroundColor: fullScreenStatus ? 'var(--bs-body-bg)' : undefined,
                zIndex: fullScreenStatus ? 1 : undefined,
                overflow: fullScreenStatus ? 'scroll' : undefined,
              }}>
                <Wrapper />
            </div>
          </Provider>
          <Portal id='portal-notification'>
            <ReactNotifications />
          </Portal>
      </ThemeProvider>
    </>
  );

}

export default App;

import { useEffect } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import Spinner from '../../widgets/Spinner';
import WorkspaceRestService from './services/WorkspaceRestService';
import Workspace from './types/Workspace';
import CreateWorkspace from './CreateWorkspace';
import WorkspaceCard from './WorkspaceCard';

const WorkspaceList = () => {
  const { isFetching: isFetchingWorkspaces, data: workspaces } = useAppSelector(
    (state: any) => state.workspace.list,
  );
  const { data: user, isFetching: isFetchingUser } = useAppSelector((store) => store.user.details);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(WorkspaceRestService.fetchWorkspaces());
  }, [dispatch]);

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='h4 fw-bold py-3'>Workspaces</div>
        </div>

        {isFetchingWorkspaces || isFetchingUser ? (
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ minHeight: '50vh' }}>
            <Spinner size={50} tag={'div'} />
          </div>
        ) : (
          <>
            <div className='col-md-6 col-sm-12 col-lg-3 integration'>
              <CreateWorkspace />
            </div>
            {workspaces.map((workspace: Workspace, idx: number) => {
              const isOwner = workspace.created_by === user.id;
              return <WorkspaceCard key={idx} idx={idx} workspace={workspace} isOwner={isOwner} />;
            })}
          </>
        )}
      </div>
    </>
  );
};

export default WorkspaceList;

import { configureStore } from '@reduxjs/toolkit';

import logger from 'redux-logger'
import NotificationSlice from '../components/notification/slice/NotificationSlice';
import ProductivitySlice from '../components/productivity/slice/ProductivitySlice'
import QualitySlice from '../components/quality/slice/QualitySlice';
import RepositorySlice from '../components/repository/slice/RepositorySlice';
import UserSlice from '../components/user/slice/UserSlice';
import WorkspaceSlice from '../components/workspace/slice/WorkspaceSlice';
import SummarySlice from '../components/summary/slice/SummarySlice';
import IntegrationSlice from '../components/integrations/slice/IntegrationSlice';


export const store = configureStore({
  reducer: {
    "integration": IntegrationSlice.reducer,
    "summary": SummarySlice.reducer,
    "notifications": NotificationSlice.reducer,
    "productivity": ProductivitySlice.reducer,
    "quality": QualitySlice.reducer,
    "repository": RepositorySlice.reducer,
    "user": UserSlice.reducer,
    "workspace": WorkspaceSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;

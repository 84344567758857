import { createSlice } from '@reduxjs/toolkit';
import LocalStorage from '../../../libs/LocalStorage';
import UserRestService from '../services/UserRestService';
import AuthRestService from '../../auth/services/AuthRestService';

interface TUserState {
  token: string | null,
  auth: {
    isFetching: boolean,
    data: boolean,
  },
  register: {
    isFetching: boolean,
  },
  details: {
    isFetching: boolean,
    data: {
      id: string,
      firstname?: string,
      lastname?: string,
      email?: string,
      integrations?: any,
      subscription?: any
    },
  },
  app: {
    installation: {
      isFetching: boolean,
      status: any,
    }
  },
  role: string
}

const initialState: TUserState = {
  token: LocalStorage.getFromStorage("token"),
  auth: {
    isFetching: false,
    data: false,
  },
  register: {
    isFetching: false,
  },
  details: {
    isFetching: false,
    data: {
      id: "",
      integrations: {}
    },
  },
  app: {
    installation: {
      isFetching: false,
      status: {},
    }
  },
  role: "USER"
};

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action) => {
      const token  = action.payload
      LocalStorage.putInStorage("token", token); 
      state.token = token;
      state.auth.data = (token !== undefined && token.length > 0);
    },
    resetState: () => initialState
  },
  extraReducers: builder => {

    builder.addCase(UserRestService.fetchUserDetails.fulfilled, (state, action) => {
      state.details.data = action.payload.data;
      state.details.isFetching = false;
    });
    builder.addCase(UserRestService.fetchUserDetails.pending, (state) => {
      state.details.isFetching = true;
    });

    builder.addCase(UserRestService.logoutUser.fulfilled, (state, action) => {
      LocalStorage.removeFromStorage("token")
      state.token = null
    });

    builder.addCase(UserRestService.checkAppInstalled.fulfilled, (state, action) => {
      state.app.installation.status = action.payload.status
      state.role = action.payload.role
      state.app.installation.isFetching = false;
    });
    builder.addCase(UserRestService.checkAppInstalled.pending, state => {
      state.app.installation.isFetching = true;
    });
    builder.addCase(UserRestService.checkAppInstalled.rejected, state => {
      state.app.installation.isFetching = false;
    });


    builder.addCase(AuthRestService.authenticateUser.fulfilled, (state, action) => {
      state.auth.data = action.payload;
      state.auth.isFetching = false;
    });
    builder.addCase(AuthRestService.authenticateUser.pending, (state) => {
      state.auth.isFetching = true;
    });
    builder.addCase(AuthRestService.authenticateUser.rejected, (state) => {
      state.auth.isFetching = true;
    });


    builder.addCase(AuthRestService.registerUser.fulfilled, (state) => {
      state.register.isFetching = false;
    });
    builder.addCase(AuthRestService.registerUser.pending, (state) => {
      state.register.isFetching = true;
    });
    builder.addCase(AuthRestService.registerUser.rejected, (state) => {
      state.register.isFetching = true;
    });

  },
});

export const UserActions = UserSlice.actions;

export default UserSlice;

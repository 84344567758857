import { createSlice } from '@reduxjs/toolkit';
import ProductivityRestService from '../services/ProductivityRestService';

const initialState = {
  branchContribution: {
    isFetching: false,
    data: {
      labels: [],
      datasets: [],
    },
  },
  userContribution: {
    isFetching: false,
    data: {
      labels: [],
      datasets: [],
    },
  },
  branchLeaderboard: {
    isFetching: false,
    data: {
      labels: [],
      datasets: [],
    },
  },
  teamContributionOnIssues: {
    isFetching: false,
    data: {
      labels: [],
      datasets: [],
    },
  },
  userContributionOnIssues: {
    isFetching: false,
    data: {
      labels: [],
      datasets: [],
    },

  },
  userAvgTimeOnIssues: {
    isFetching: false,
    data: {
      labels: [],
      datasets: [],
    }
  },
  averagePRClosingTime: {
    isFetching: false,
    data: {
      labels: [],
      datasets: [],
    }
  },
  activeAndNewPRs: {
    isFetching: false,
    data: {
      labels: [],
      datasets: [],
    },
  },
  commitsOnTicket: {
    isFetching: false,
    data: {
      labels: [],
      datasets: [],
    },
  },
};

const updateActiveAndNewPRs = (state: any, action: any) => {
  const { active, dates } = action.payload;
  const newlyCreated = action.payload["newly_created"]

  state.activeAndNewPRs = {
    data: {
      labels: dates,
      datasets: [active, newlyCreated],
    },
    isFetching: false,
  };
};

const updateAveragePRClosingTime = (state: any, action: any) => {
  const { times, dates } = action.payload;

  state.averagePRClosingTime = {
    data: {
      labels: dates,
      datasets: [times],
    },
    isFetching: false,
  }
};

const updateBranchContributionAction = (state: any, action: any) => {
  const { branches, source_prs } = action.payload;

  if (branches && source_prs) {
    state.branchContribution = {
      data: {
        labels: branches,
        datasets: source_prs,
      },
      isFetching: false
    }
  }
};

const updateUserContributionAction = (state: any, action: any) => {
  const { totalUsers, activeUsers, dates } = action.payload;

  state.userContribution = {
    data: {
      labels: dates,
      datasets: [totalUsers, activeUsers],
    },
    isFetching: false
  }
};

const updateBranchLeaderboardAction = (state: any, action: any) => {
  const labels = [];
  const commits = [];
  for(let contribution of action.payload){
    labels.push(contribution["title"]);
    commits.push(contribution["value"]);
  }

  state.branchLeaderboard = {
    data : {
      labels,
      datasets: [commits],
    },
    isFetching: false
  }
};

const updateTeamContributionOnIssuesAction = (state: any, action: any) => {
  const { avgTimeToCloseTickets, noOfTicketsClosed, dates } = action.payload;

  const totalIssues = noOfTicketsClosed.reduce(
    (partialSum: number, a: number) => partialSum + a,
    0
  );

  state.teamContributionOnIssues = {
    data: {
      labels: dates,
      datasets: [
        avgTimeToCloseTickets,
        noOfTicketsClosed.map((n: number) => Math.round((n / totalIssues) * 100)),
      ],
    },
    isFetching: false
  }
};

const updateUserContributionOnIssuesAction = (state: any, action: any) => {
  const { users, contribution } = action.payload;
  state.userContributionOnIssues = {
    data: {
      labels: users,
      datasets: contribution,
    },
    isFetching: false
  };
}

const updateUserAvgTimeOnIssuesAction = (state: any, action: any) => {
  const { users, hours } = action.payload;
  state.userAvgTimeOnIssues = {
    data: {
      labels: users,
      datasets: [hours],
    },
    isFetching: false
  }
};

const updateCommitsOnTicketAction = (state: any, action: any) => {
  const { tickets, commits } = action.payload;
  state.commitsOnTicket = {
    data: {
      labels: tickets,
      datasets: [commits],
    },
    isFetching: false
  };
};

export const ProductivitySlice = createSlice({
  name: 'productivity',
  initialState,
  reducers: {
    updateBranchContribution: updateBranchContributionAction,
    updateUserContribution: updateUserContributionAction,
  },
  extraReducers: builder => {
    builder.addCase(
      ProductivityRestService.fetchBranchContribution.fulfilled,
      updateBranchContributionAction
    );
    builder.addCase(ProductivityRestService.fetchBranchContribution.pending, state => {
      state.branchContribution.isFetching = true;
    });

    
    builder.addCase(
      ProductivityRestService.fetchUserContribution.fulfilled,
      updateUserContributionAction
    );
    builder.addCase(ProductivityRestService.fetchUserContribution.pending, state => {
      state.branchContribution.isFetching = true;
    });


    builder.addCase(
      ProductivityRestService.fetchBranchLeaderboard.fulfilled,
      updateBranchLeaderboardAction
    );
    builder.addCase(ProductivityRestService.fetchBranchLeaderboard.pending, state => {
      state.branchLeaderboard.isFetching = true;
    });


    builder.addCase(
      ProductivityRestService.fetchTeamContributionOnIssues.fulfilled,
      updateTeamContributionOnIssuesAction
    );
    builder.addCase(ProductivityRestService.fetchTeamContributionOnIssues.pending, state => {
      state.teamContributionOnIssues.isFetching = true;
    });


    builder.addCase(
      ProductivityRestService.fetchUserContributionOnIssues.fulfilled,
      updateUserContributionOnIssuesAction
    );
    builder.addCase(ProductivityRestService.fetchUserContributionOnIssues.pending, state => {
      state.branchContribution.isFetching = true;
    });


    builder.addCase(
      ProductivityRestService.fetchUserAverageTimeOnIssues.fulfilled,
      updateUserAvgTimeOnIssuesAction
    );
    builder.addCase(ProductivityRestService.fetchUserAverageTimeOnIssues.pending, state => {
      state.branchContribution.isFetching = true;
    });


    builder.addCase(
      ProductivityRestService.fetchPRClosingTime.fulfilled,
      updateAveragePRClosingTime
    );
    builder.addCase(ProductivityRestService.fetchPRClosingTime.pending, state => {
      state.averagePRClosingTime.isFetching = true;
    });


    builder.addCase(
      ProductivityRestService.fetchActiveAndNewPRs.fulfilled,
      updateActiveAndNewPRs
    );
    builder.addCase(ProductivityRestService.fetchActiveAndNewPRs.pending, state => {
      state.activeAndNewPRs.isFetching = true;
    });


    builder.addCase(
      ProductivityRestService.fetchCommitsOnTicket.fulfilled,
      updateCommitsOnTicketAction
    );
    builder.addCase(ProductivityRestService.fetchCommitsOnTicket.pending, state => {
      state.commitsOnTicket.isFetching = true;
    });
  },
});

// Action creators are generated for each case reducer function
export const { updateBranchContribution } = ProductivitySlice.actions;

export default ProductivitySlice;

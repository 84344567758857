import PageWrapper from "../../layouts/PageWrapper/PageWrapper";
import Page from "../../layouts/Page/Page";
import RepositoryContributionChart from "./charts/RepositoryContributionChart";
import BranchContributionChart from "./charts/BranchContributionChart";
import TeamContributionOnIssuesChart from "./charts/TeamContributionOnIssuesChart";
import UserContributionOnIssuesChart from "./charts/UserContributionOnIssuesChart";
import PRClosingTimeChart from "./charts/PRClosingTimeChart";
import ResolvedNewPRChart from "./charts/ResolvedNewPRChart";
import CommitsPerIssueChart from "./charts/CommitsPerIssueChart";
import UserAvgTimeOnIssuesChart from "./charts/UserAvgTimeOnIssuesChart";
import BranchLeaderboardChart from "./charts/BranchLeaderboardChart";

const Productivity = () => {

  return (
    <PageWrapper
      isProtected={false}
      title={'Productivity'}
      className={'p-0'}>
      <Page container="fluid" className="px-5">
        <div className="row">
          <div className="col-md-12">
            <RepositoryContributionChart />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-6">
            <BranchContributionChart />
          </div>
          <div className="col-sm-12 col-md-6">
            <TeamContributionOnIssuesChart />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-6">
            <UserContributionOnIssuesChart />
          </div>
          <div className="col-sm-12 col-md-6">
            <PRClosingTimeChart />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-6">
            <ResolvedNewPRChart />
          </div>
          <div className="col-sm-12 col-md-6">
            <CommitsPerIssueChart />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-6">
            <BranchLeaderboardChart />
          </div>
          <div className="col-sm-12 col-md-6">
            <UserAvgTimeOnIssuesChart />
          </div>
        </div>

      </Page>
    </PageWrapper>
  );
};

export default Productivity;

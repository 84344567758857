import { FC, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import WorkspaceRestService from "./services/WorkspaceRestService";

interface ISelectedWorkspace { }

const SelectedWorkspace: FC<ISelectedWorkspace> = () => {

  const location = useLocation();
  const dispatch = useAppDispatch();

  const match = location.pathname.match(new RegExp('(workspace)(/)([a-z0-9-]{36})'))

  const workspaceId = (match != null) ? match[3] : null;

  useEffect(() => {
    if (workspaceId != null) {
      dispatch(WorkspaceRestService.fetchWorkspace(workspaceId));
    }
  }, [dispatch, workspaceId]);

  return (
    <div className="">
      <Outlet />
    </div>
  );
}

SelectedWorkspace.propTypes = {};
SelectedWorkspace.defaultProps = {};

export default SelectedWorkspace;

